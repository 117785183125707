import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import axios from 'axios';
import Apiurl from './apiurl';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom';

function Payoutdetails() {
    const apiu = Apiurl();
    const params = useParams();
    const settlementId = params.merchantid;
    console.log(params.merchantid);
    console.log(settlementId);

    // const [settlement, setSettlement] = useState([]);
    const [order_id, setOrderid] = useState("");
    const [amount, setAmount] = useState("");
    const [ifsc_code, setIfsccode] = useState("");
    const [account_number, setAccountnumber] = useState("");
    const [account_name, setAccountname] = useState("");
    const [transfer_type, setTransfertype] = useState("");
    const [datetime, setDatetime] = useState("");
    const [type, setType] = useState("");
    const [cost, setCost] = useState("");
    const [remarks, setRemarks] = useState("");
    const [bank_name, setBankname] = useState("");
    const [email, setEmail] = useState("");
    const [mobile_no, setMobileno] = useState("");
    const [merchant_id, setMerchantid] = useState("");
    const [merchant_name, setMerchantname] = useState("");
    const [status_code, setStatuscode] = useState("");
    const [response_msg, setResponsemsg] = useState("");


    useEffect(() => {
        axios.get(apiu + "/payouts/getpayout?oid=" + settlementId,
        {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
          // setSettlement(response.data.settlement);
          let settlement = response.data.settlement[0];
          // console.log(response.data);

          setDatetime(settlement.datetime);
          // console.log(settlement.datetime);
          setAmount(settlement.amount);
          setCost(settlement.cost);
          setOrderid(settlement.order_id);
          setIfsccode(settlement.ifsc_code);
          setAccountnumber(settlement.account_number);
          setAccountname(settlement.account_name);
          setTransfertype(settlement.transfer_type);
          setType(settlement.type);
          setRemarks(settlement.remarks);
          setBankname(settlement.bank_name);
          setMobileno(settlement.mobile_no);
          setEmail(settlement.email);
          setStatuscode(settlement.status_code);
          setResponsemsg(settlement.response_msg);

          const merchantid = settlement.merchant_id;
          setMerchantid(merchantid);
          axios.get(apiu + "/merchants/getmerchantdetails?merchant_id=" + merchantid, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
            // console.log(response2.data.merchant);
            // console.log(merchantid);
            setMerchantname(response2.data.merchant.merchant_name);
          }).catch((error) => {
            console.log(error);
          });

        }).catch((error) => {
          console.log(error);
        });

    },[]);    

    // console.log(settlement);
  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Payout Details</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="border-b border-gray-200">
            <div className="sm:flex sm:items-baseline">
                <a href={'/payouts'} className="text-indigo-600 hover:text-indigo-900">
                    Back
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <h3 className="text-base font-semibold leading-6 text-gray-900">Payout Transaction Details</h3>
                
            </div>
            </div>

            <br></br>

            <div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1">
                    <div className="text-xl overflow-auto rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <h3>Payout Details</h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Merchant Name:&nbsp;&nbsp;<span className='font-semibold'>{merchant_name}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Order ID:&nbsp;&nbsp;<span className='font-semibold'>{order_id}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Date:&nbsp;&nbsp;<span className='font-semibold'>{datetime}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Amount:&nbsp;&nbsp;<span>{amount}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Cost:&nbsp;&nbsp;<span>{cost}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Type:&nbsp;&nbsp;<span>{type}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Remarks:&nbsp;&nbsp;<span className='font-semibold'>{remarks}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">IFSC Code:&nbsp;&nbsp;<span>{ifsc_code}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Account Number:&nbsp;&nbsp;<span>{account_number}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Account Name:&nbsp;&nbsp;<span>{account_name}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Transfer Type:&nbsp;&nbsp;<span>{transfer_type}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Bank Name:&nbsp;&nbsp;<span>{bank_name}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Email:&nbsp;&nbsp;<span>{email}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Phone No:&nbsp;&nbsp;<span>{mobile_no}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Status Code:&nbsp;&nbsp;<span>{status_code}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Response Message:&nbsp;&nbsp;<span className="break-normal">{response_msg}</span></h3>
                    </div>
                    
                </div>
            </div>
        </div>
    </main>
    </>
  );
}

export default Payoutdetails;
