import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import axios from 'axios';
import Apiurl from './apiurl';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom';
import { relativeTimeRounding } from 'moment';

function Editmerchant() {
    const apiu = Apiurl();
    const params = useParams();
    const { merchantid } = params;
    // console.log(merchantid)

    const [loading, setLoading] = useState(false);
  
    const [merchantname, setMerchantname] = useState([]);
    const [merchant_upi_ids, setUpi] = useState({});
    // const [people, setPeople] = useState([]);
    const [mstatus, setMstatus] = useState([]);
    const [mstatuses, setMstatuses] = useState([]);

    const [people, setPeople] = useState([]);
    const [statuses, setStatuses] = useState([]);
    
    const [inputminValues, setInputMinValues] = useState([]);
    let [inputminVal, setInputMinVal] = useState({});

    const [inputmaxValues, setInputMaxValues] = useState([]);
    let [inputmaxVal, setInputMaxVal] = useState({});

    const [rules, setRules] = useState([]);

    const [algonum, setAlgonum] = useState("");
    const [algocurnum, setAlgocurnum] = useState("");
    const handlealgonumchange = (e) => {
        setAlgonum(e.target.value);
    };

    const handlealgosubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("algonum", algonum);
        formData.append("merchant_id", merchantid);
        axios
        .post(apiu + "/merchants/setalgosettings", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            console.log(response)
            alert("algo settings saved successfully");
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
        });
    };


    useEffect(() => {
        axios.get(apiu + "/merchants/getmerchantdetails?merchant_id=" + merchantid, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            const var1 = response.data.merchant.merchant_name;
            setMerchantname(var1);
            const var2 = response.data.merchant.upi_ids;
            setUpi(var2);
            const var3 = response.data.merchant.algo_number;
            setAlgocurnum(var3);
            // console.log(response.data.merchant.algo);
            
            const login_block_var = {id: 'Login Block', status: response.data.merchant.login_block};
            const transaction_block_var = {id: 'Transaction Block', status: response.data.merchant.transaction_block};
            const algo_var = {id: 'Algo', status: response.data.merchant.algo};
            const Mstatusesvar = [login_block_var, transaction_block_var, algo_var];
            setMstatus(Mstatusesvar);
            let initialMstatuses = {};
            Mstatusesvar.forEach((mstat) => {
                initialMstatuses[mstat.id] = mstat.status;
            });
            setMstatuses(initialMstatuses);

            const fetchData = async () => {
                let newrules = null;
                await axios.get(apiu + "/merchants/listupirules?merchant_id=" + merchantid, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response3) => {
                    setRules(response3.data.rules);
                    newrules = response3.data.rules;
                    // console.log(response3.data.rules);
                }).catch((error) => {console.log(error);});

                await axios.get(apiu + "/upi/getupilist", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
                    // console.log(newrules.length);
                    let data_list = [];
                    response2.data.upi_list.forEach((person) => {
                        // console.log(person);
                        let maxval = '';
                        let minval = '';
                        if (person.upi_status === true){
                            newrules.forEach((rule, index)=>{
                                // console.log(rule['vpa']);
                                if (person.vpa == rule['vpa']) {
                                    // console.log(rule);
                                    maxval = rule['max'];
                                    minval = rule['min'];
                                }
                            });
                            data_list.push({vpa: person.vpa, name: person.upi_name, status: person.upi_status, max: maxval, min: minval});
    
                            // console.log(rules);
                        } 
                    });
                    setPeople(data_list);
                    
                    // console.log(var2);
                    let initialStatuses = {};
                    // console.log(merchant_upi_ids)
                    data_list.forEach((person) => {
                        // console.log(person.vpa);
                        if (var2.includes(person.vpa)) {
                            initialStatuses[person.vpa] = true;
                        }
                    });
                    setStatuses(initialStatuses);
        
                    // merchant_upi_ids.forEach((upi_id_var) => {
                    //     console.log(upi_id_var);
                    // })
                    // data_list.forEach((person) => {
                    //     initialStatuses[person.vpa] = person.status;
                    //     // console.log(person.status);
                    // });
                    // setStatuses(initialStatuses);
        
                }).catch((error) => {
                    console.log(error);
                });

            };
            
            fetchData();
        
            // console.log(response.data.merchant.upi_ids);
            
            
        }).catch((error) => {
            console.log(error);
        });

    }, []);
    
    // console.log(merchant_upi_ids);
    // const [mstatus, setMstatus] = useState([
    //     { id: 'Login Block', status: false },
    //     { id: 'Transaction Block', status: false}
    // ]);

    // const [mstatuses, setMstatuses] = useState(() => {
    //     const initialMstatuses = {};
    //     mstatus.forEach((mstat) => {
    //         initialMstatuses[mstat.id] = mstat.status;
    //     });
    //     return initialMstatuses;
    // });

    const handleMstatusChange = (id) => {
        const newMstatuses = { ...mstatuses };
        newMstatuses[id] = !newMstatuses[id];
        // setMstatuses(newMstatuses);
        // console.log(`${id}: ${newMstatuses[id] ? true : false}`)
        // console.log(id);
        // console.log(newMstatuses[id]);
        console.log(merchantid);
        if(id == "Algo") {
            const formData = new FormData();
            formData.append("merchant_id", merchantid);
            axios.post(apiu + "/merchants/changealgopermit", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
                console.log(response.data.success);
                setMstatuses(newMstatuses);
            }).catch((error) => {
                console.log(error);
            });
        }
        if(id == "Login Block") {
            // if (newMstatuses[id] == true) {
            //     console.log("Activate login block")
            // } else {
            //     console.log("Deactivate login block")
            // }
            const formData = new FormData();
            formData.append("merchant_id", merchantid);
            axios.post(apiu + "/merchants/changeloginblock", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
                console.log(response.data.success);
                setMstatuses(newMstatuses);
            }).catch((error) => {
                console.log(error);
            });
        }
        if(id == "Transaction Block") {
            // if (newMstatuses[id] == true) {
            //     console.log("Activate transaction block")
            // } else {
            //     console.log("Deactivate transaction block")
            // }
            const formData = new FormData();
            formData.append("merchant_id", merchantid);
            axios.post(apiu + "/merchants/changetransactionblock", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
                console.log(response.data.success);
                setMstatuses(newMstatuses);
            }).catch((error) => {
                console.log(error);
            });
        }
    };


    // const [people, setPeople] = useState([
    //     { vpa: 'upi1@vpa',  name: 'Upi 1', status: false },
    //     { vpa: 'upi2@vpa',  name: 'Upi 2', status: true },
    //     { vpa: 'upi3@vpa',  name: 'Upi 3', status: true },
    //     { vpa: 'upi4@vpa',  name: 'Upi 4', status: false },
    //   ]);
    
    //   const [statuses, setStatuses] = useState(() => {
    //     const initialStatuses = {};
    //     people.forEach((person) => {
    //       initialStatuses[person.vpa] = person.status;
    //     });
    //     return initialStatuses;
    //   });
    function resetfunc() {
        console.log('reset');
        const formData = new FormData();
        formData.append("merchant_id", merchantid);
        
        axios
        .post(apiu + "/merchants/resetupirules", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}})
        .then((response) => {
            alert('all rules are reset');
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
            alert(error.message);
            // window.location.reload();
        });
    };

    function saverules() {
        setLoading(true);
        let minlen = Object.keys(inputminVal).length;
        let maxlen = Object.keys(inputmaxVal).length;
        console.log(minlen);
        console.log(maxlen);
        if (minlen == 0 || maxlen == 0) {
            alert("No new rules added");
        }
        if (minlen != maxlen) {
            alert("Fill all the values in UPI range");
        } else {
            let newarr = [];
            let vpa = null;
            let min = null;
            let max = null;
            Object.entries(inputminVal).map(([key, value]) => (
                vpa = key,
                min = value,
                max = inputmaxVal[key],
                newarr.push({vpa, min, max})
            ));
            console.log(newarr);

            const formData = new FormData();
            formData.append("merchant_id", merchantid);

            formData.append("upi_ids", JSON.stringify(newarr));

            axios
            .post(apiu + "/merchants/setupirules", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}})
            .then((response) => {
                alert('new rules are set successfully');
                window.location.reload();
            })
            .catch((error) => {
                alert(error.message);
                window.location.reload();
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleInputminChange = (index, event) => {
        let oldvalues = inputminVal;
        oldvalues[index] = event.target.value;
        let newvalues = oldvalues;
        setInputMinVal(newvalues);
    };

    const handleInputmaxChange = (index, event) => {
        let oldvalues = inputmaxVal;
        oldvalues[index] = event.target.value;
        let newvalues = oldvalues;
        setInputMaxVal(newvalues);
    };


      const handleStatusChange = (vpa) => {
        const newStatuses = { ...statuses };
        newStatuses[vpa] = !newStatuses[vpa];
        // setStatuses(newStatuses);
        // console.log(`${vpa}: ${newStatuses[vpa] ? true : false}`);
        console.log(vpa);
        console.log(newStatuses[vpa]);
        console.log(merchantid);
        if (newStatuses[vpa] == true) {
            console.log("Add upi id to merchant")
            const formData = new FormData();
            formData.append("merchant_id", merchantid);
            formData.append("vpa", vpa);
            axios.post(apiu + "/merchants/addupiid", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
                console.log(response.data.success);
                setStatuses(newStatuses);
                window.location.reload();
            }).catch((error) => {
                console.log(error);
            });
        } else {
            console.log("Remove upi id from merchant")
            const formData = new FormData();
            formData.append("merchant_id", merchantid);
            formData.append("vpa", vpa);
            axios.post(apiu + "/merchants/removeupiid", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
                console.log(response.data.success);
                if (response.data.success === "upi id removed merchant deactivated") {
                    alert("last upi id removed, merchant deactivated");
                    setStatuses(newStatuses);
                    window.location = '/merchants';
                } else {
                    setStatuses(newStatuses);
                    window.location.reload()
                }
            }).catch((error) => {
                console.log(error);
            });
        }
      };

      function updateUpi() {
        console.log(people);
      }

    const tabs = [
        { name: 'List', href: '/merchants', current: false },
        { name: 'Add New', href: '/addmerchant', current: true },
      ]
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Merchants</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="border-b border-gray-200">
            <div className="sm:flex sm:items-baseline">
                <a href={'/merchants'} className="text-indigo-600 hover:text-indigo-900">
                    Back
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <h3 className="text-base font-semibold leading-6 text-gray-900">Edit Merchant {merchantid}</h3>
                
                {/* <div className="mt-4 sm:ml-10 sm:mt-0">
                <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                    <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                        tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                    >
                        {tab.name}
                    </a>
                    ))}
                </nav>
                </div> */}
            </div>
            </div>

            <br></br>

            <div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1">
                    <div className="text-xl overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <h1 className="font-semibold">{merchantname}</h1>
                        <h1>No of active upi id: <span className="font-semibold">{Object.keys(merchant_upi_ids).length}</span></h1>
                        <br></br> 
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Name
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Vpa
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Status
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                from Amount
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                to Amount
                            </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {people.map((person) => (
                            <tr key={person.vpa}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {person.name}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {person.vpa}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" value="" className="sr-only peer"
                                    checked={statuses[person.vpa] || false}
                                    onChange={() => handleStatusChange(person.vpa)}
                                    />
                                    {statuses[person.vpa] ? true : false}
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                                </td>
                                <td>
                                    <input type="number" disabled={!(statuses[person.vpa])} className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-1 sm:text-sm sm:leading-6 rounded-md disabled:opacity-20"
                                    placeholder={person.min} onChange={(event) => handleInputminChange(person.vpa, event)}></input>
                                </td>
                                <td>
                                    <input type="number" disabled={!(statuses[person.vpa])} className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-1 sm:text-sm sm:leading-6 rounded-md disabled:opacity-20"
                                    placeholder={person.max} onChange={(event) => handleInputmaxChange(person.vpa, event)}></input>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                
                    </div>
                    
                </div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <button
                        type="submit"
                        onClick={resetfunc}
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Reset Rules
                    </button>
                    <button
                        type="submit"
                        onClick={saverules}
                        disabled={loading}
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        {loading ? (
                        <div
                            style={{
                                border: '5px solid #f3f3f3',
                                borderTop: '5px solid #3498db',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                animation: 'spin 2s linear infinite',
                                marginRight: '10px'
                            }}
                        ></div> // Render the loader when loading is true
                        ) : (
                        'Save Rules'
                        )}
                                        
                    </button>
                </div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <div className="text-xl overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <h1 className="font-semibold">&nbsp;</h1>
                        <h1><span className="font-semibold">Permission Status</span></h1>
                        <br></br>
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Permission Name
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                status
                            </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {mstatus.map((mstat) => (
                            <tr key={mstat.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {mstat.id}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" value="" className="sr-only peer"
                                    checked={mstatuses[mstat.id] || false}
                                    onChange={() => handleMstatusChange(mstat.id)}
                                    />
                                    {mstatuses[mstat.id] ? true : false}
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    <div className="text-xl overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <h1 className="font-semibold">&nbsp;</h1>
                        <h1><span className="font-semibold">Algo Settings</span></h1>
                        <br></br>
                        <form onSubmit={handlealgosubmit}>
                            
                            <label htmlFor="upiname" className="block text-sm font-medium leading-6 text-gray-900">
                                Algo Skip Number
                            </label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <input
                                    type="Number"
                                    name="algoskipno"
                                    id="algoskipno"
                                    value={algonum}
                                    onChange={handlealgonumchange}
                                    autoComplete="algoskipno"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder={algocurnum}
                                    required
                                />
                                </div>
                            </div>
                            <br></br>
                            <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save Settings
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </main>
    </>
  );
}

export default Editmerchant;
