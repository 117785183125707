import { useEffect , useState} from "react";
import React from 'react';
import Navbar from './Navbar';
import 'flowbite/dist/flowbite.min.css';
import Datepicker from 'flowbite-datepicker/Datepicker';
import Apiurl from "./apiurl";
import axios from "axios";
import Chart1 from "./chart1";
import Chart2 from "./chart2";
import Chart3 from "./chart3";
import Chart4 from "./chart4";
import Chart5 from "./chart5";


function DashBoard() {
    const apiu = Apiurl();
    
    const formatter = new Intl.NumberFormat('en-IN', {})

    const [start_date, setStartdate] = useState('');
    const [end_date, setEnddate] = useState('');
    const [start_date_filter, setStartdatefilter] = useState('');
    const [end_date_filter, setEnddatefilter] = useState('');
    
    const [merchantlist, setMerchantlist] = useState([]);
    const [merchant, setMerchant] = useState('');
    const [merchant_filter, setMerchantfilter] = useState('');
    const [upiidlist, setUpiidlist] = useState([]);
    const [upiid, setUpiid] = useState('');
    const [upiid_filter, setUpiidfilter] = useState('');
    
    const [successamt, setSuccessamt] = useState(0);
    const [successnum, setSuccessnum] = useState(0);
    const [pendingamt, setPendingamt] = useState(0);
    const [pendingnum, setPendingnum] = useState(0);
    const [failedamt, setFailedamt] = useState(0);
    const [failednum, setFailednum] = useState(0);
    const [totaltnum, setTotaltnum] = useState(0);
    const [successrate, setSuccessrate] = useState(0);
    const [thirtymsuccessrate, setThirtymsuccess] = useState(0);
    const [fifteenmsuccessrate, setFifteenmsuccess] = useState(0);

    const handleMerchantchange = (event) => {
        setMerchant(event.target.value);
    }
    
    const handleUpiidchange = (event) => {
        setUpiid(event.target.value);
    }

    function handleDate1(event) {
        setStartdate(event.target.value);
    }
    
    function handleDate2(event) {
        setEnddate(event.target.value);
    }
    
    const[stats, setStats] = useState([]);

    
    function handlech() {

    }

    function refreshPage() {
    window.location.reload();
    }


    useEffect(() => {
        const datepickerElstart = document.getElementById('datepickerstart');
            new Datepicker(datepickerElstart, {
                // options
                "datepicker-autohide": true,
                "datepicker-format": "dd/mm/yyyy"
        }); 

        const datepickerElend = document.getElementById('datepickerend');
            new Datepicker(datepickerElend, {
                // options
                "datepicker-autohide": true,
                "datepicker-format": "dd/mm/yyyy"
        }); 

        axios.get(apiu + "/merchants/listallmerchants", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            let data_list = [];
            response.data.merchants_list.forEach((person) => {
                data_list.push({id: person.merchant_id, name: person.merchant_id, mcode: person.merchant_id, appname: person.merchant_name});
            });
            setMerchantlist(data_list);
        }).catch((error) => {
            console.log(error);
        });

        axios.get(apiu + "/upi/getupilist", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            let data_list = [];
            response.data.upi_list.forEach((person) => {
                data_list.push({id: person.upi_name, name: person.vpa, status:person.upi_status});
            });
            data_list.push({id: 'bandhanfilter', name: 'Getepay.gpdr', status: true})
            setUpiidlist(data_list);  
        }).catch((error) => {
            console.log(error);
        });
    
        let pending_amount = 0;
        let pending_transactions = 0;
        let success_amount = 0;
        let success_transactions = 0;
        let failed_amount = 0;
        let failed_transactions = 0;
        let fifteenmsuccess_init = 0;
        let thirtymsuccess_init = 0;
        let thirty_success_rate_init = 0;
        let fifteen_success_rate_init = 0;

        axios.get(apiu + "/analytics/defaultdashboard", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            let initialStats = [];
            console.log(response.data);
            response.data.forEach(element => {
                console.log(element._id);
                if(element._id == "pending") {
                    pending_amount = element.totalAmount;
                    pending_transactions = element.totalTransactions;
                }
                if(element._id == "success") {
                    success_amount = element.totalAmount;
                    success_transactions = element.totalTransactions;
                }
                if(element._id == "failed") {
                    failed_amount = element.totalAmount;
                    failed_transactions = element.totalTransactions;
                }
                let total_transactions = pending_transactions + success_transactions + failed_transactions;
                let success_rate = (success_transactions / total_transactions) * 100;
                success_rate = Math.round(success_rate * 100) / 100;

                var dateObj = new Date();
                var tyear = dateObj.getFullYear();
                var tmonth = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                var tday = dateObj.getDate().toString().padStart(2, '0');
                var dttoday = `${tyear}${tmonth}${tday}`;

                // console.log(dttoday);
                axios.get(apiu + `/analytics/getsuccessgraphdata?date=${dttoday}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
                    var pointer = 0;
                    response.data["success_transactions"].forEach(element_init => {
                        // console.log(pointer);
                        // console.log(parseFloat(element_init["y"]));
                        thirtymsuccess_init += element_init["y"];
                        if (pointer < 15) {
                            fifteenmsuccess_init += element_init["y"];
                        }
                        pointer += 1;
                    });

                    console.log(thirtymsuccess_init);
                    console.log(fifteenmsuccess_init);
                    fifteen_success_rate_init = fifteenmsuccess_init / 15;
                    fifteen_success_rate_init = Math.round(fifteen_success_rate_init * 100) / 100;
                    thirty_success_rate_init = thirtymsuccess_init / 30;
                    thirty_success_rate_init = Math.round(thirty_success_rate_init * 100) / 100;

                    console.log(fifteen_success_rate_init);
                    console.log(thirty_success_rate_init);

                    initialStats = [
                        { name: 'Success Amount', stat: formatter.format(success_amount) },
                        { name: 'Successful Transactions', stat: formatter.format(success_transactions) },
                        { name: 'Failed Amount', stat: formatter.format(failed_amount) },
                        { name: 'Failed Transactions', stat: formatter.format(failed_transactions) }, 
                        { name: 'Pending Amount', stat: formatter.format(pending_amount) },
                        { name: 'Pending Transactions', stat: formatter.format(pending_transactions) },
                        { name: 'Total Transactions', stat: formatter.format(total_transactions) },
                        { name: 'Success Rate', stat: formatter.format(success_rate) }, 
                        { name: '15 Minute Success Rate', stat: formatter.format(fifteen_success_rate_init) },
                        { name: '30 Minute Success Rate', stat: formatter.format(thirty_success_rate_init) }
                    ];
                    
                    // var pointer2 = 0;
                    // response.data["total_transactions"].forEach(element => {
                    //     // console.log(element["y"]);
                    //     thirtymtotal += element["y"];
                    //     if (pointer2 < 15) {
                    //         fifteenmtotal += element["y"];
                    //     }
                    //     pointer2 += 1;
                    // });
                }).catch((error) => {
                    console.log(error);
                });
                // success_amount = formatNumber(success_amount);
                // failed_amount = formatNumber(failed_amount);
                // pending_amount = formatNumber(pending_amount);
                
                // console.log(fifteenmtotal);
                // console.log(thirtymtotal);

                // if (fifteenmtotal !== 0) {
                //     var fifteen_success_rate_init = (fifteenmsuccess / fifteenmtotal) * 100;
                //     fifteen_success_rate_init = Math.round(fifteen_success_rate_init * 100) / 100;
                // } else {
                //     var fifteen_success_rate_init = 0;
                // }

                // if (thirtymtotal !== 0) {
                //     var thirty_success_rate_init = (thirtymsuccess / thirtymtotal) * 100;
                //     thirty_success_rate_init = Math.round(thirty_success_rate_init * 100) / 100;
                // } else {
                //     var thirty_success_rate_init = 0;
                // }

                // console.log(fifteen_success_rate_init);
                // console.log(thirty_success_rate_init);

                
                // initialStats = [
                //     { name: 'Success Amount', stat: formatter.format(success_amount) },
                //     { name: 'Successful Transactions', stat: formatter.format(success_transactions) },
                //     { name: 'Failed Amount', stat: formatter.format(failed_amount) },
                //     { name: 'Failed Transactions', stat: formatter.format(failed_transactions) }, 
                //     { name: 'Pending Amount', stat: formatter.format(pending_amount) },
                //     { name: 'Pending Transactions', stat: formatter.format(pending_transactions) },
                //     { name: 'Total Transactions', stat: formatter.format(total_transactions) },
                //     { name: 'Success Rate', stat: formatter.format(success_rate) }, 
                //     { name: '15 Minute Success Rate', stat: formatter.format(fifteen_success_rate_init) },
                //     { name: '30 Minute Success Rate', stat: formatter.format(thirty_success_rate_init) }
                // ];
                // setStats(initialStats);
            });
            setStats(initialStats);
          }).catch((error) => {
            console.log(error);
          });
    }, []);

    useEffect(() => {
        let initialDates = [];
        const datesArrfunc = async () => {
            await axios.get(apiu + "/transactions/listalltcols", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((result) => {
                if(start_date_filter === '' | end_date_filter === '') {
                    let dt_display = String(result.data[0].date).slice(5, 16);
                    initialDates.push({id: result.data[0].strdate, name: dt_display});
                } else {
                    result.data.forEach(element => {
                        let dt_display = String(element.date).slice(5, 16);
                        let formateddate = new Date(element.date);
                        if (formateddate >= start_date_filter & formateddate <= end_date_filter) {
                            initialDates.push({id: element.strdate, name: dt_display});
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
        };

        datesArrfunc().then(() => {
            console.log(initialDates);
            const fetchData = async () => {
                let counts = 0;
                let countf = 0;
                let countp = 0;
                let totals = 0;
                let totalf = 0;
                let totalp = 0;
                let totalt = 0;
                let successrat = 0;
                let fifteen_success_rate = 0;
                let thirty_success_rate = 0;

                for (const date of initialDates) {
                  try {
                    const response = await axios.get(apiu + `/analytics/gettransactiondata?date=${date.id}&merchant_code=${merchant_filter}&merchant_upi=${upiid_filter}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}});
                    for (const datarow of response.data) {
                        if(datarow._id == "failed") { countf += datarow.totalTransactions; totalf += datarow.totalAmount; }
                        if(datarow._id == "success") { counts += datarow.totalTransactions; totals += datarow.totalAmount; }
                        if(datarow._id == "pending") { countp += datarow.totalTransactions; totalp += datarow.totalAmount; }
                    }

                    var dateObj = new Date();
                    var tyear = dateObj.getFullYear();
                    var tmonth = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                    var tday = dateObj.getDate().toString().padStart(2, '0');
                    var dttoday = `${tyear}${tmonth}${tday}`;

                    var fifteenmsuccess = 0;
                    var thirtymsuccess = 0;
                    
                    const response2 = await axios.get(apiu + `/analytics/getsuccessgraphdata?date=${dttoday}&merchant_code=${merchant_filter}&merchant_upi=${upiid_filter}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}});
                    // console.log(response2.data["success_transactions"]);
                    // console.log(response2.data["total_transactions"]);
                    
                    var pointer = 0;
                    response2.data["success_transactions"].forEach(element => {
                        // console.log(pointer);
                        // console.log(element["y"]);
                        thirtymsuccess += element["y"];
                        if (pointer < 15) {
                            fifteenmsuccess += element["y"];
                        }
                        pointer += 1;
                    });

                  } catch (error) {
                    console.error(error);
                  }
                }
                totalt = counts + countp + countf;
                successrat = (counts / totalt) * 100;
                successrat = Math.round(successrat * 100) / 100;

                // if (fifteenmtotal !== 0) {
                //     var fifteen_success_rate = (fifteenmsuccess / fifteenmtotal) * 100;
                //     fifteen_success_rate = Math.round(fifteen_success_rate * 100) / 100;
                // } else {
                //     var fifteen_success_rate = 0;
                // }

                // if (thirtymtotal !== 0) {
                //     var thirty_success_rate = (thirtymsuccess / thirtymtotal) * 100;
                //     thirty_success_rate = Math.round(thirty_success_rate * 100) / 100;
                // } else {
                //     var thirty_success_rate = 0;
                // }
                
                fifteen_success_rate = fifteenmsuccess / 15;
                fifteen_success_rate = Math.round(fifteen_success_rate * 100) / 100;
                thirty_success_rate = thirtymsuccess / 30;
                thirty_success_rate = Math.round(thirty_success_rate * 100) / 100;

                // console.log(fifteen_success_rate);
                // console.log(thirty_success_rate);

                setSuccessnum(counts);
                setSuccessamt(totals);
                setFailednum(countf);
                setFailedamt(totalf);
                setPendingnum(countp);
                setPendingamt(totalp);
                setTotaltnum(totalt);
                setSuccessrate(successrat);
                setFifteenmsuccess(fifteen_success_rate);
                setThirtymsuccess(thirty_success_rate);
                // setTransactionsCount(count);
            };
            fetchData();
        });

    }, [start_date_filter, end_date_filter, upiid_filter, merchant_filter]);

    useEffect(() => {
        // successamt = formatNumber(successamt);
        // failedamt = formatNumber(failedamt);
        // pendingamt = formatNumber(pendingamt);  

        let initialStats = [
            { name: 'Success Amount', stat: formatter.format(successamt) },
            { name: 'Successful Transactions', stat: formatter.format(successnum) },
            { name: 'Failed Amount', stat: formatter.format(failedamt) },
            { name: 'Failed Transactions', stat: formatter.format(failednum) },
            { name: 'Pending Amount', stat: formatter.format(pendingamt) },
            { name: 'Pending Transactions', stat: formatter.format(pendingnum) },
            { name: 'Total Transactions', stat: formatter.format(totaltnum) },
            { name: 'Success Rate', stat: formatter.format(successrate) }, 
            { name: '15 Minute Success Rate', stat: formatter.format(fifteenmsuccessrate) },
            { name: '30 Minute Success Rate', stat: formatter.format(thirtymsuccessrate) }
        ];

        setStats(initialStats);
    }, [successamt, successnum, failedamt, failednum, pendingamt, pendingnum, totaltnum, successrate, fifteenmsuccessrate, thirtymsuccessrate]);

    function applyFilter() {
        setSuccessamt(0);
        setSuccessnum(0);
        setFailedamt(0);
        setFailednum(0);
        setPendingamt(0);
        setPendingnum(0);
        setTotaltnum(0);
        setSuccessrate(0);

        console.log("hello");
        if ((start_date !== '' & end_date === '') | (start_date === '' & end_date !== '')) {
            alert("please select both start date and end date");
        } else {
            let startdateformated = new Date(start_date);
            let enddateformated = new Date(end_date);
            if (!isNaN(startdateformated.getTime()) & !isNaN(enddateformated.getTime())) {
              if(startdateformated < enddateformated) {
                console.log(startdateformated);
                console.log(enddateformated);
                setStartdatefilter(startdateformated);
                setEnddatefilter(enddateformated);
              }
              if(startdateformated > enddateformated) {
                alert("end date cannot be less than start date");
              }
            }
        }
        
        if(merchant !== '') {
            setMerchantfilter(merchant);
        } else {
            setMerchantfilter('');
        }
        if(upiid !== '') {
            setUpiidfilter(upiid);
        } else {
            setUpiidfilter('');
        }

    }

  return (
    <> 
    <div className="min-h-full">
    <div className="bg-indigo-600 pb-32">
    <Navbar />
    <header className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">DashBoard</h1>
        </div>
    </header>
    </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="float-container h-20">
                <div className="float-child" style={{width: "10%"}}>
                    <div className="relative max-w-sm" >
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <input value={start_date} onBlur={handleDate1} onChange={handlech} id="datepickerstart" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="start date"/>
                    </div>
                    </div>
                <div className="float-child" style={{width: "10%"}}>
                    <div className="relative max-w-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <input value={end_date} onBlur={handleDate2} onChange={handlech} id="datepickerend" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="end date"/>
                    </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={merchant} 
                    onChange={handleMerchantchange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- select merchant -- </option>
                    {merchantlist.map((mr) => (
                      // div className="user">{user}</div>
                      <option key={mr.id} value={mr.name}>
                        {mr.appname}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                    <div>
                  <select
                    value={upiid} 
                    onChange={handleUpiidchange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- select upi id -- </option>
                    {upiidlist.map((upi) => (
                      // div className="user">{user}</div>
                      <option key={upi.id} value={upi.name}>
                        {upi.name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                    <button
                        onClick={applyFilter}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Apply Filters
                    </button>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                    <button
                        onClick={refreshPage}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Clear Filters
                    </button>
                </div>
            </div>
            
            <br></br>
            <div>
                {/* <br></br><br></br><br></br> */}
                <h3 className="text-base font-semibold leading-6 text-gray-900">Stats</h3>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    {stats.map((item) => (
                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                    </div>
                    ))}
                </dl>
            </div>
            <br></br>
            <div style={{height: "33rem"}} className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                    <div className="px-4 py-5 sm:px-6 bg-blue-500">
                        <p className="font-semibold text-gray-50">7 Days Successful Transaction Value</p>
                    </div>
                    <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
                        <Chart1 merchant={merchant_filter} upiid={upiid_filter}/>
                    </div>
                </div>
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                    <div className="px-4 py-5 sm:px-6 bg-blue-500">
                        <p className="font-semibold text-gray-50">7 Days Success Rate</p>
                    </div>
                    <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
                        <Chart2 merchant={merchant_filter} upiid={upiid_filter}/>
                    </div>
                </div>
            </div>
            <br></br>
            <div style={{height: "33rem"}} className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                    <div className="px-4 py-5 sm:px-6 bg-blue-500">
                        <p className="font-semibold text-gray-50">24h Total Transactions</p>
                    </div>
                    <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
                        <Chart3 merchant={merchant_filter} upiid={upiid_filter}/>
                        
                    </div>
                </div>
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                    <div className="px-4 py-5 sm:px-6 bg-blue-500">
                        <p className="font-semibold text-gray-50">24h Successful Transactions</p>
                    </div>
                    <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
                        <Chart4 merchant={merchant_filter} upiid={upiid_filter}/>
                        
                    </div>
                </div>
            </div>
            <br></br>
            <div style={{height: "25rem"}} className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1">
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                    <div className="px-4 py-5 sm:px-6 bg-blue-500">
                        <p className="font-semibold text-gray-50">30m Success Rate</p>
                    </div>
                    <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
                        <Chart5 merchant={merchant_filter} upiid={upiid_filter}/>
                    </div>
                </div>
            </div>
        </div>
    </main>
    
    </>
    );
}

export default DashBoard;
