import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react'
import Apiurl from './apiurl';
import axios from 'axios';


function Upi() {
  const apiu = Apiurl();
  const [people, setPeople] = useState([]);
  const [statuses, setStatuses] = useState({});

  useEffect(() => {
    axios.get(apiu + "/upi/getupilist", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
      let initialStatuses = {};
      let data_list = [];
      response.data.upi_list.forEach((person) => {
        data_list.push({name: person.upi_name, vpa: person.vpa, limit: person.daily_limit, status: person.upi_status});
      });
      setPeople(data_list);
      console.log(data_list);
      data_list.forEach((person) => {
        initialStatuses[person.vpa] = person.status;
        // console.log(person.status);
      });
      setStatuses(initialStatuses);
      
    }).catch((error) => {
      console.log(error);
    });
  }, []);

    // const [people, setPeople] = useState([
    //     { name: 'Upi 1', vpa: 'upi1@vpa', limit: null, status: false },
    //     { name: 'Upi 2', vpa: 'upi2@vpa', limit: 100, status: true },
    //     { name: 'Upi 3', vpa: 'upi3@vpa', limit: 100, status: true },
    //     { name: 'Upi 4', vpa: 'upi4@vpa', limit: 50, status: false },
    //   ]);
    
    //   const [statuses, setStatuses] = useState(() => {
    //     const initialStatuses = {};
    //     people.forEach((person) => {
    //       initialStatuses[person.vpa] = person.status;
    //     });
    //     return initialStatuses;
    //   });
    
      const handleStatusChange = (vpa) => {
        const newStatuses = { ...statuses };
        newStatuses[vpa] = !newStatuses[vpa];
        // setStatuses(newStatuses);
        // console.log(`${vpa}: ${newStatuses[vpa] ? true : false}`);
        console.log(vpa);
        console.log(newStatuses[vpa]);

        const formData = new FormData();
        formData.append("vpa", vpa);
        if (newStatuses[vpa] === true) {
          formData.append("upi_status", newStatuses[vpa])
        }
        axios.post(apiu + "/upi/setupistatus", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
          console.log(response.data.success);
          setStatuses(newStatuses);
        }).catch((error) => {
           // console.log(error);
           alert(error.response.data.error);
        });

      };

    const tabs = [
        { name: 'List', href: '/upi', current: true },
        { name: 'Add New', href: '/addupi', current: false },
      ]
      

      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Upi</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="border-b border-gray-200">
            <div className="sm:flex sm:items-baseline">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Upi Ids</h3>
                <div className="mt-4 sm:ml-10 sm:mt-0">
                <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                    <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                        tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                    >
                        {tab.name}
                    </a>
                    ))}
                </nav>
                </div>
            </div>
            </div>

            <br></br>

            <div className="px-4 sm:px-6 lg:px-8">
      
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      VPA
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Limit
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people.map((person) => (
                    <tr key={person.vpa}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {person.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.vpa}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.limit}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer"
                            checked={statuses[person.vpa] || false}
                            onChange={() => handleStatusChange(person.vpa)}
                            />
                            {statuses[person.vpa] ? true : false}
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

        </div>
    </main>
    </>
  );
}

export default Upi;
