import React from 'react';
import Navbar from './Navbar';
import Apiurl from './apiurl';
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import axios from 'axios';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'


function Addmerchant() {
    const apiu = Apiurl();

    let [merchantname, setMerchant] = useState("");
    let [appname, setAppname] = useState("");
    let [website, setWebsite] = useState("");
    let [number, setNumber] = useState("");
    let [email, setEmail] = useState("");
    let [cname, setCname] = useState("");
    let [caddress, setCaddress] = useState("");
    let [accountno, setAccountno] = useState("");
    let [ifsc, setIfsc] = useState("");
    let [paycharge, setPaycharge] = useState("");
      
    const handleMerchantChange = (e) => {
        setMerchant(e.target.value);
    };
    
    const handleAppnameChange = (e) => {
        setAppname(e.target.value);
    }

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
    }

    const handleNumberChange = (e) => {
        setNumber(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleCnameChange = (e) => {
        setCname(e.target.value);
    }

    const handleCaddressChange = (e) => {
        setCaddress(e.target.value);
    }

    const handleAccountnoChange = (e) => {
        setAccountno(e.target.value);
    }

    const handleIfscChange = (e) => {
        setIfsc(e.target.value);
    }

    const handlePaychargeChange = (e) => {
        setPaycharge(e.target.value);
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("email_id", email);
        formData.append("name", merchantname);
        formData.append("app_name", appname);
        formData.append("website", website);
        formData.append("mobile_number", number);
        formData.append("company_name", cname);
        formData.append("company_address", caddress);
        formData.append("bank_account_number", accountno);
        formData.append("ifsc_code", ifsc);
        formData.append("payin_charge", paycharge);

       axios
      .post(apiu + "/merchants/createmerchant", formData, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
      })
      .then((response) => {
        alert("merchant added succesfully");
        window.location = '/merchants';
        console.log(response)
      })
      .catch((error) => {
        console.log(error);
      });

    };

    const tabs = [
        { name: 'List', href: '/merchants', current: false },
        { name: 'Add New', href: '/addmerchant', current: true },
      ]
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Merchants</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="border-b border-gray-200">
            <div className="sm:flex sm:items-baseline">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Merchants</h3>
                <div className="mt-4 sm:ml-10 sm:mt-0">
                <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                    <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                        tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                    >
                        {tab.name}
                    </a>
                    ))}
                </nav>
                </div>
            </div>
            </div>

            <br></br>

            <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Add new merchant</h2>
                    
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="merchantname" className="block text-sm font-medium leading-6 text-gray-900">
                            Merchant Name
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="merchantname"
                                value={merchantname}
                                onChange={handleMerchantChange}
                                id="merchantname"
                                autoComplete="merchantname"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="name"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="appname" className="block text-sm font-medium leading-6 text-gray-900">
                            App Name
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="appname"
                                value={appname}
                                onChange={handleAppnameChange}
                                id="appname"
                                autoComplete="appname"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="sample app"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                            Website
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="website"
                                value={website}
                                onChange={handleWebsiteChange}
                                id="website"
                                autoComplete="website"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="https://example.com"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="number" className="block text-sm font-medium leading-6 text-gray-900">
                            Mobile Number
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="number"
                                value={number}
                                onChange={handleNumberChange}
                                id="number"
                                autoComplete="number"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="9988123456"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email Address
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                id="email"
                                autoComplete="email"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="email@example.com"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="cname" className="block text-sm font-medium leading-6 text-gray-900">
                            Company Name
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="cname"
                                value={cname}
                                onChange={handleCnameChange}
                                id="cname"
                                autoComplete="cname"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="abc pvt ltd."
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="caddress" className="block text-sm font-medium leading-6 text-gray-900">
                            Company Address
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="caddress"
                                value={caddress}
                                onChange={handleCaddressChange}
                                id="caddress"
                                autoComplete="caddress"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder=""
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="accountno" className="block text-sm font-medium leading-6 text-gray-900">
                            Registered Bank Account Number
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="accountno"
                                value={accountno}
                                onChange={handleAccountnoChange}
                                id="accountno"
                                autoComplete="accountno"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder=""
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="ifsc" className="block text-sm font-medium leading-6 text-gray-900">
                            Registered Bank Account IFSC Number
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="ifsc"
                                value={ifsc}
                                onChange={handleIfscChange}
                                id="ifsc"
                                autoComplete="ifsc"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder=""
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="paycharge" className="block text-sm font-medium leading-6 text-gray-900">
                            PayIn percentage charge
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                min="0"
                                type="number"
                                name="paycharge"
                                value={paycharge}
                                onChange={handlePaychargeChange}
                                id="paycharge"
                                autoComplete="paycharge"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="5%"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>



                    </div>
                </div>

                <div className="mt-6 flex items-center gap-x-6">
                    
                    <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Save
                    </button>
                </div>
            </form>

        </div>
    </main>
    </>
  );
}

export default Addmerchant;
