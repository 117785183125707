import { lighten, useTheme } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Apiurl from "./apiurl";
import axios from 'axios';


const Chart2 = (props) => {
  const theme = useTheme();
  const [awaitRender, setAwaitRender] = useState(true);

const [series, setSeries] = useState({'success-data': {}});
const [labels, setLabels] = useState([]);

const apiu = Apiurl();
    var dateObj = new Date();

    var tyear = dateObj.getFullYear();
    var tmonth = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    var tday = dateObj.getDate().toString().padStart(2, '0');

    var dttoday = `${tyear}${tmonth}${tday}`;
    useEffect(() => {
        axios.get(apiu + `/analytics/getbargraphdata?date=${dttoday}&merchant_code=${props.merchant}&merchant_upi=${props.upiid}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            // console.log(response.data["successrate"].reverse());
            setSeries({"success-data": [{"data":response.data["successrate"].reverse(), "name": "Success Rate", "type": "column"}],});
            setLabels(response.data["labels"].reverse());
            
        }).catch((error) => {
            console.log(error);
        });
    }, [props.merchant, props.upiid]);

  const chartOptions = {
    chart: {
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '100%',
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    labels,
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      background: {
        borderWidth: 0,
      },
    },
    grid: {
      borderColor: theme.palette.divider,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.75,
        },
      },
    },
    stroke: {
      width: [3, 0],
    },
    tooltip: {
      followCursor: true,
      theme: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        offsetX: -16,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };

  useEffect(() => {
    setAwaitRender(false);
  }, []);

  if (awaitRender) {
    return null;
  }

  return (
    <div className="flex flex-col flex-auto">
    <ReactApexChart
        className="flex-auto w-full"
        options={chartOptions}
        series={series['success-data']}
        height={320}
    />
    </div>
  );
}

export default memo(Chart2);
