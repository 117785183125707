import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


import { Switch } from '@headlessui/react'
import axios from 'axios';
import Apiurl from './apiurl';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom';

function Editsettlement() {
    const apiu = Apiurl();
    const params = useParams();
    const { settlementId } = params;
    // console.log(settlementId)

    const [loading, setLoading] = useState(false);

    // const [settlement, setSettlement] = useState([]);
    const [created_at, setCreatedat] = useState("");
    const [amount, setAmount] = useState("");
    const [cost, setCost] = useState("");
    const [status, setStatus] = useState("");
    const [notxn, setNotxn] = useState("");
    const [start_time, setStarttime] = useState("");
    const [end_time, setEndtime] = useState("");
    const [merchant_id, setMerchantid] = useState("");
    const [merchant_name, setMerchantname] = useState("");
    const [approvrrn, setApprovalrrn] = useState("");
    const [commentshow, setCommentshow] = useState("");

    const [data, setData] = useState({destination_address: '', exchange_rate: '', gas_fees: '', usdt_balance: '', trx_balance: '', usdt_amount: ''});
    const [showAlert, setShowAlert] = useState(false);

    let [rrn, setRrn] = useState("");
    let [comment, setComment] = useState("");

    const handlerrnchange = (e) => {
        setRrn(e.target.value);
    };

    const handlecommentchange = (e) => {
        setComment(e.target.value);
    };

    useEffect(() => {
        axios.get(apiu + "/settlements/getsettlement?oid=" + settlementId,
        {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
          // setSettlement(response.data.settlement);
          let settlement = response.data.settlement[0];
          
          setCreatedat(settlement.created_at.$date);
          setAmount(settlement.amount);
          setCost(settlement.cost);
          setStatus(settlement.status);
          setNotxn(settlement.no_of_success_txn);
          setStarttime(settlement.start_time.$date);
          setEndtime(settlement.end_time.$date);
          setApprovalrrn(settlement.approval_rrn);
          setCommentshow(settlement.comment);
          const merchantid = settlement.merchant_id;
          setMerchantid(merchantid);
          axios.get(apiu + "/merchants/getmerchantdetails?merchant_id=" + merchantid, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
            // console.log(response2.data.merchant);
            // console.log(merchantid);
            setMerchantname(response2.data.merchant.merchant_name);
          }).catch((error) => {
            console.log(error);
          });

        }).catch((error) => {
          console.log(error);
        });

    },[]);    
    
    function usdtHandle() {
        console.log(settlementId);
        console.log(rrn);
        console.log(comment);
        console.log("usdt approve");

        const formData = new FormData();
        formData.append("oid", settlementId);
        
        axios.post(apiu + "/settlements/usdtsettlement", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
            // alert("settlement approved");
            // window.location = '/settlements';
            setData(response2.data);
            setShowAlert(true);
            console.log(response2.data);
            // console.log(merchantid);
            // setMerchantname(response2.data.merchant.merchant_name);
        }).catch((error) => {
            console.log(error);
        });

    }

    const handleConfirm = async () => {
        setLoading(true);
        console.log("MAKE PAYMENT");
        const formData = new FormData();
        formData.append("oid", settlementId);
        formData.append("final_amt", JSON.stringify(data.usdt_amount));
        formData.append("usdtb", JSON.stringify(data.usdt_balance));
        console.log(formData);

        axios.post(apiu + "/settlements/makeusdtpayment", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
            setShowAlert(false);
            console.log(response2.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    };
    
      const handleCancel = () => {
        setShowAlert(false);
      };
    
      const cancelButtonRef = useRef(null)

    function approveHandle() {
        console.log(settlementId);
        console.log(rrn);
        console.log(comment);
        console.log("approve");

        const formData = new FormData();
        formData.append("oid", settlementId);
        formData.append("approval_rrn", rrn);
        formData.append("comment", comment);

        axios.post(apiu + "/settlements/approvesettlement", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
            alert("settlement approved");
            window.location = '/settlements';

            // console.log(response2.data.merchant);
            // console.log(merchantid);
            // setMerchantname(response2.data.merchant.merchant_name);
        }).catch((error) => {
            console.log(error);
        });
    };

    function rejectHandle() {
        console.log(settlementId);
        console.log(rrn);
        console.log(comment);
        console.log("decline");

        const formData = new FormData();
        formData.append("oid", settlementId);
        // formData.append("approval_rrn", rrn);
        formData.append("comment", comment);

        axios.post(apiu + "/settlements/rejectsettlement", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
            alert("settlement declined");
            window.location = '/settlements';

            // console.log(response2.data.merchant);
            // console.log(merchantid);
            // setMerchantname(response2.data.merchant.merchant_name);
        }).catch((error) => {
            console.log(error);
        });
    };

    function holdHandle() {
        console.log(settlementId);
        console.log(rrn);
        console.log(comment);
        console.log("hold");

        const formData = new FormData();
        formData.append("oid", settlementId);
        // formData.append("approval_rrn", rrn);
        formData.append("comment", comment);
        
        axios.post(apiu + "/settlements/holdsettlement", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
            alert("settlement on hold");
            window.location = '/settlements';

            // console.log(response2.data.merchant);
            // console.log(merchantid);
            // setMerchantname(response2.data.merchant.merchant_name);
        }).catch((error) => {
            console.log(error);
        });

    };

    // console.log(settlement);
  return (
    <>

<Transition.Root show={showAlert} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShowAlert}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-50"
          leave="ease-in duration-200"
          leaveFrom="opacity-50"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      you are going to make a USDT Payment!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-900">
                        <b>Destination Address: {JSON.stringify(data.destination_address)}</b><br></br>
                        <b>Exchange Rate: {JSON.stringify(data.exchange_rate)}</b><br></br>
                        <b>Gas Fees: {JSON.stringify(data.gas_fees)}</b><br></br>
                        <b>USDT Balance: {JSON.stringify(data.usdt_balance)}</b><br></br>
                        <b>TRX Balance: {JSON.stringify(data.trx_balance)}</b><br></br>
                        <br></br>
                        <b>Final Amount: {JSON.stringify(data.usdt_amount)}</b><br></br>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    disabled={loading}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={handleConfirm}
                  >
                    {loading ? (
                        <div
                            style={{
                                border: '5px solid #f3f3f3',
                                borderTop: '5px solid #3498db',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                animation: 'spin 2s linear infinite',
                                marginRight: '10px'
                            }}
                        ></div> // Render the loader when loading is true
                        ) : (
                        'Make Payment'
                        )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setShowAlert(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>


    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Merchants</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="border-b border-gray-200">
            <div className="sm:flex sm:items-baseline">
                <a href={'/settlements'} className="text-indigo-600 hover:text-indigo-900">
                    Back
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <h3 className="text-base font-semibold leading-6 text-gray-900">Edit Settlement</h3>
                
            </div>
            </div>

            <br></br>

            <div>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <div className="text-xl overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <h3>Settlement Details</h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Merchant Name:&nbsp;&nbsp;<span className='font-semibold'>{merchant_name}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Settlement Date:&nbsp;&nbsp;<span className='font-semibold'>{created_at}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Amount:&nbsp;&nbsp;<span>{amount}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Cost:&nbsp;&nbsp;<span>{cost}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Status:&nbsp;&nbsp;<span>{status}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">No of Transactions:&nbsp;&nbsp;<span>{notxn}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Start DateTime:&nbsp;&nbsp;<span>{start_time}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">End DateTime:&nbsp;&nbsp;<span>{end_time}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Approval RRN:&nbsp;&nbsp;<span>{approvrrn}</span></h3>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Comment:&nbsp;&nbsp;<span>{commentshow}</span></h3>
                        
                    </div>
                    <div className="text-xl overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <label htmlFor="rrn" className="block text-sm font-medium leading-6 text-gray-900">
                            Approval Rrn
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            
                        <input
                            type="text"
                            name="rrn"
                            id="rrn"
                            value={rrn}
                            onChange={handlerrnchange}
                            autoComplete="rrn"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="rrn"
                            required
                        />
                        </div></div>
                        <br></br>
                        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                            Comment
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            
                        <input
                            type="text"
                            name="comment"
                            id="comment"
                            value={comment}
                            onChange={handlecommentchange}
                            autoComplete="comment"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="comment"
                            required
                        /></div></div>
                        <br></br><br></br>
                        <div className='row'>
                            <div className='six columns'>
                                <button
                                onClick={approveHandle}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Approve
                                </button>
                            </div>
                            <div className='six columns'>
                                <button
                                onClick={usdtHandle}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Pay USDT
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <div className='row'>
                            <div className='six columns'>
                                <button
                                onClick={holdHandle}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                Hold
                                </button>
                            </div>
                            <div className='six columns'>
                                <button
                                onClick={rejectHandle}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                Decline
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </main>
    </>
  );
}

export default Editsettlement;
