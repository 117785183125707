import Profile from './Profile';
import Signin from './Signin';
import React from 'react';
import DashBoard from './Dashboard';
import Upi from './Upi';
import Addupi from './Addupi'
import Addmerchant from './Addmerchant';
import Merchant from './Merchant';
import Transactions from './Transactions';
import Settlement from './Settlement';
import Editmerchant from './Editmerchant';
import PrivateRoutes from './Protected'
import SignOut from './Signout';
import Settings from './Settings';
import Editsettlement from './Editsettlement';
import './skeleton.css'
import './App.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Chart3 from './chart3';
import Payouts from './Payouts';
import Fixtransactions from './fixtransactions';
import ManagePayouts from './Managepayouts';
import Payoutdetails from './Payoutdetails';
import ConversionComponent from './usdt';
import Transactiondetails from './Transactiondetails'; 


function App() {
  return (
    <>
      <Router>
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/signin">SignIn</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
          </ul>
        </nav> */}

        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/signout" element={<SignOut />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/upi" element={<Upi />} />
            <Route path="/addupi" element={<Addupi />} />
            <Route path="/merchants" element={<Merchant />} />
            <Route path="/addmerchant" element={<Addmerchant />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/settlements" element={<Settlement />} />
            <Route path="/editmerchant/:merchantid" element={<Editmerchant />} />
            <Route path="/payoutdetails/:merchantid" element={<Payoutdetails />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/payouts" element={<Payouts/>} />
            <Route path="/managepayouts" element={<ManagePayouts/>} />
            <Route path="/editsettlement/:settlementId" element={<Editsettlement />} />
            <Route path="/charttest" element={<Chart3/>} />
            <Route path="/fixtransactions" element={<Fixtransactions/>} />
            <Route path="/usdttest" element={<ConversionComponent/>} />
            <Route path="/transactiondetails" element={<Transactiondetails/>} />
            {/* <Route path="/transactiontest" element={<TransactionsPage />} />
            <Route path="/dashboardtest" element={<DashBoardtest />} /> */}
          </Route>
        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;
