import React from 'react';
import Navbar from './Navbar';
import Apiurl from './apiurl';
import 'flowbite/dist/flowbite.min.css';
import { useEffect , useState} from "react";
import DateRangePicker from 'flowbite-datepicker/DateRangePicker';
import Datepicker from 'flowbite-datepicker/Datepicker';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import axios from 'axios';
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'


function Transactions() {
  const apiu = Apiurl();

  const formatter = new Intl.NumberFormat('en-IN', {})

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [transactions, setTransactions] = useState([]);
  const [tlength, setTlength] = useState(0);
  const [page, setPage] = useState(1);
  const [strdt, setStrdt] = useState('');
  const [start_date, setStartdate] = useState('');
  const [end_date, setEnddate] = useState('');
  const [start_date_filter, setStartdatefilter] = useState('');
  const [end_date_filter, setEnddatefilter] = useState('');
  const [rrntext, setRrntext] = useState('');
  const [rrn_filter, setRrnfilter] = useState('');
  const [tridtext, setTridtext] = useState('');
  const [trid_filter, setTridfilter] = useState('');
  const [mridtext, setMridtext] = useState('');
  const [mrid_filter, setMridfilter] = useState('');

  const handlerrntextChange = (e) => {
    setRrntext(e.target.value);
  };

  const handletridtextChange = (e) => {
    setTridtext(e.target.value);
  };

  const handlemridtextChange = (e) => {
    setMridtext(e.target.value);
  };


  function handleDate1(event) {
    setStartdate(event.target.value);
    // console.log('change detected');
  }

  function handleDate2(event) {
    setEnddate(event.target.value);
    // console.log('change detected2');
  }

  function handlech() {

  }
  
  const handlePreviousPage = () => {
    setPage(prevPage => prevPage - 1);
    // setStrdt(selected);
    //console.log(`current page = ${page}`);
    //console.log(`transaction len = ${transactions.length}`);
    //console.log(`selected = ${selected}`);
  };

  const handleNextPage = () => {
    setPage(prevPage => prevPage + 1);
    // setStrdt(selected);
    //console.log(`current page = ${page}`);
    //console.log(`transaction len = ${transactions.length}`);
    //console.log(`selected = ${selected}`);
  };

  const [people, setPeople] = useState([]);
  const [selected, setSelected] = useState({});

  const [merchantlist, setMerchantlist] = useState([]);
  const [merchant, setMerchant] = useState('');
  const [merchant_filter, setMerchantfilter] = useState('');
  const [statuslist, setStatuslist] = useState([{id: 1, name: 'pending'}, {id: 2, name: 'success'}, {id: 3, name: 'failed'}])
  const [status, setStatus] = useState('');
  const [status_filter, setStatusfilter] = useState('');
  const [upiidlist, setUpiidlist] = useState([]);
  const [upiid, setUpiid] = useState('');
  const [upiid_filter, setUpiidfilter] = useState('');
  useEffect(()=> {
    
    const datepickerElstart = document.getElementById('datepickerstart');
    new Datepicker(datepickerElstart, {
        // options
        "datepicker-autohide": true,
        "datepicker-format": "dd/mm/yyyy"
    }); 

    const datepickerElend = document.getElementById('datepickerend');
    new Datepicker(datepickerElend, {
        // options
        "datepicker-autohide": true,
        "datepicker-format": "dd/mm/yyyy"
    }); 

    //console.log(`start date = ${new Date(start_date)}`);
    //console.log(`end date = ${new Date(end_date)}`);
    
    // let startdateformated = new Date(start_date);
    // let enddateformated = new Date(end_date);
    // console.log(typeof(startdateformated));
    // console.log();
    axios.get(apiu + "/merchants/listallmerchants", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
      // let initialStatuses = {};
      let data_list = [];
      response.data.merchants_list.forEach((person) => {
        // let upi_count = Object.keys(person.upi_ids).length;
        data_list.push({id: person.merchant_id, name: person.merchant_id, mcode: person.merchant_id, appname: person.merchant_name});
      });
      setMerchantlist(data_list);
      // data_list.forEach((person) => {
      //   initialStatuses[person.mcode] = person.status;
      //   // console.log(person.status);
      // });
      // setStatuses(initialStatuses);
      
    }).catch((error) => {
      console.log(error);
    });

    axios.get(apiu + "/upi/getupilist", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
      let data_list = [];
      response.data.upi_list.forEach((person) => {
        // let upi_count = Object.keys(person.upi_ids).length;
        data_list.push({id: person.upi_name, name: person.vpa, status:person.upi_status});
      });
      data_list.push({id: 'bandhanfilter', name: 'Getepay.gpdr', status: true})
      setUpiidlist(data_list);  
    }).catch((error) => {
      console.log(error);
    });

    axios.get(apiu + "/transactions/listalltcols", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
      //console.log(typeof(start_date_filter));
      //console.log(typeof(end_date_filter));
      if(start_date_filter === '' | end_date_filter === '') {
        let intialPeople = [];
        response.data.forEach(element => {
          let dt_display = String(element.date).slice(5, 16);
          intialPeople.push({id: element.strdate, name: dt_display});
        });
        setPeople(intialPeople);
        setStrdt(intialPeople[0]["id"]);
      } else {
        let intialPeople = [];
        response.data.forEach(element => {
          let dt_display = String(element.date).slice(5, 16);
          let formateddate = new Date(element.date);
          // console.log(formateddate);
          if (formateddate >= start_date_filter & formateddate <= end_date_filter) {
            intialPeople.push({id: element.strdate, name: dt_display});
          }
        });
        if (intialPeople.length === 0) {
          setPeople([]);
          setStrdt('');
          setPage(1);
          setTransactions([]);
        } else {
          setPeople(intialPeople);
          //console.log(intialPeople[0]["id"]);
          setStrdt(intialPeople[0]["id"]);
          setPage(1);
        }
        // console.log(intialPeople.length);
        
      }
    }).catch((error) => {
      console.log(error);
    });

  }, [start_date_filter, end_date_filter]);

  useEffect(() => {
    axios.get(apiu + `/transactions/listalltransactions?page=${page}&date=${strdt}&merchant_upi=${upiid_filter}&status=${status_filter}&merchant_code=${merchant_filter}&mrtid=${mrid_filter}&tid=${trid_filter}&rrn=${rrn_filter}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
      // let initialTransactions = [];
      console.log(`transactions=${response2.data.length}`);
      setTlength(response2.data.length);
      setTransactions(response2.data);
    }).catch((error) => {
      console.log(error);
    });
    // setStrdt(strdt);
    // setStrdt(people[0]);
    setSelected(strdt);
    console.log(strdt);

    
  },[page, strdt, upiid_filter, status_filter, merchant_filter, mrid_filter, trid_filter, rrn_filter]);

  
  function refreshPage() {
    window.location.reload();
  }
  
//   function filtertransactionsfirst(param, callback) {
//     filterTransactions();
//     callback();
// }

const downloadCsv = async () => {
  try {
    // Make a GET request to the Flask API
    const response = await axios({
      url: apiu +
      `/transactions/downloadtransactions?date=${strdt}&merchant_upi=${upiid}&status=${status}&merchant_code=${merchant}&mrtid=${mridtext}&tid=${tridtext}&rrn=${rrntext}`, // Update this URL to match your API
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      responseType: 'blob', // Important for receiving file data
    });

    // Create a Blob from the CSV data
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');

    // Set the URL of the link element to the object URL of the Blob
    link.href = URL.createObjectURL(blob);

    // Set the download attribute of the link to the desired file name
    link.download = 'transactions.csv';

    // Simulate a click on the link to start the download
    link.click();

    // Release the object URL
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Download failed:', error);
  }
};

  const handleClick = (event) => {
    //console.log(event.target);
    //console.log(event.target.value);
    setSelected(event.target.value);
    //console.log(page);
    //console.log(strdt);
    setPage(1);
    setStrdt(event.target.value);
    if(merchant !== '') {
      setMerchantfilter(merchant);
    } else {
      setMerchantfilter('');
    }
    if(upiid !== '') {
      setUpiidfilter(upiid);
    } else {
      setUpiidfilter('');
    }
    if(status !== '') {
      setStatusfilter(status);
    } else {
      setStatusfilter('');
    }
    if(tridtext !== '') {
      setTridfilter(tridtext)
    } else {
      setTridfilter('')
    }
    if(rrntext !== '') {
      setRrnfilter(rrntext)
    } else {
      setRrnfilter('')
    }
    if(mridtext !== '') {
      setMridfilter(mridtext)
    } else {
      setMridfilter('')
    }

    console.log(event.target.value);
    console.log(merchant);
    console.log(upiid);
    console.log(status);
  };

  const handleMerchantchange = (event) => {
    //console.log(event.target.value);
    setMerchant(event.target.value);
  }

  const handleStatuschange = (event) => {
    //console.log(event.target.value);
    setStatus(event.target.value);
  }

  const handleUpiidchange = (event) => {
    //console.log(event.target.value);
    setUpiid(event.target.value);
  }

  function filterTransactions(params, callback) {
    console.log("hello");
    if ((start_date !== '' & end_date === '') | (start_date === '' & end_date !== '')) {
      alert("please select both start date and end date");
    } else {
      let startdateformated = new Date(start_date);
      let enddateformated = new Date(end_date);
      if (!isNaN(startdateformated.getTime()) & !isNaN(enddateformated.getTime())) {
        if(startdateformated < enddateformated) {
          console.log(startdateformated);
          console.log(enddateformated);
          setStartdatefilter(startdateformated);
          setEnddatefilter(enddateformated);
        }
        if(startdateformated > enddateformated) {
          alert("end date cannot be less than start date");
        }
      }

      if(merchant !== '') {
        setMerchantfilter(merchant);
      } else {
        setMerchantfilter('');
      }
      if(upiid !== '') {
        setUpiidfilter(upiid);
      } else {
        setUpiidfilter('');
      }
      if(status !== '') {
        setStatusfilter(status);
      } else {
        setStatusfilter('');
      }
      if(tridtext !== '') {
        setTridfilter(tridtext)
      } else {
        setTridfilter('')
      }
      if(rrntext !== '') {
        setRrnfilter(rrntext)
      } else {
        setRrnfilter('')
      }
      if(mridtext !== '') {
        setMridfilter(mridtext)
      } else {
        setMridfilter('')
      }
      console.log(merchant);
      console.log(upiid);
      console.log(status);

    }
    
    if (callback !== null) {
      callback();
    }

    // console.log(typeof start_date);
    // console.log(typeof end_date);
  }

  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Transactions</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32 h-96">
        <div className="rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="float-container h-20">
                <div className="float-child" style={{width: "10%"}}>
                    <div className="relative max-w-sm" >
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <input value={start_date} onBlur={handleDate1} onChange={handlech} id="datepickerstart" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="start date"/>
                    </div>
                    </div>
                <div className="float-child" style={{width: "10%"}}>
                    <div className="relative max-w-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <input value={end_date} onBlur={handleDate2} onChange={handlech} id="datepickerend" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="end date"/>
                    </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={merchant} 
                    onChange={handleMerchantchange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- select merchant -- </option>
                    {merchantlist.map((mr) => (
                      // div className="user">{user}</div>
                      <option key={mr.id} value={mr.name}>
                        {mr.appname}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={status} 
                    onChange={handleStatuschange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- select status -- </option>
                    {statuslist.map((st) => (
                      // div className="user">{user}</div>
                      <option key={st.id} value={st.name}>
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={upiid} 
                    onChange={handleUpiidchange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- select upi id -- </option>
                    {upiidlist.map((upi) => (
                      // div className="user">{user}</div>
                      <option key={upi.id} value={upi.name}>
                        {upi.name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                    <button
                        onClick={refreshPage}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Clear Filters
                    </button>
                </div>
            </div>
            {/* <br></br><br></br><br></br><br></br><br></br> */}
            <div className="float-container h-16">
              <div className="float-child" style={{width: "20%", zIndex: "2000"}}>
              {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={selected} 
                    onChange={handleClick}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    {people.map((person) => (
                      // div className="user">{user}</div>
                      <option key={person.id} value={person.id}>
                        {person.name}
                      </option>
                    ))}
                  </select>
                </div>
  
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  <div className="relative">
                    <label
                      htmlFor="tid"
                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Transactionid
                    </label>
                    <input
                      onChange={handletridtextChange}
                      value={tridtext}
                      type="text"
                      name="tid"
                      id="tid"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Transaction Id"
                    />
                  </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  <div className="relative">
                    <label
                      htmlFor="mid"
                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Merchant Transactionid
                    </label>
                    <input
                      onChange={handlemridtextChange}
                      value={mridtext}
                      type="text"
                      name="mid"
                      id="mid"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Merchant Transaction Id"
                    />
                  </div>
                </div>
                <div className="float-child" style={{width: "15%"}}>
                  <div className="relative">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      RRN
                    </label>
                    <input
                      onChange={handlerrntextChange}
                      value={rrntext}
                      type="text"
                      name="rrn"
                      id="rrn"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Transaction Ref No"
                    />
                  </div>
                </div>
                <div className="float-child" style={{width: "10%"}}>
                    <button
                        onClick={() => filterTransactions(null, null)}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Filter Trx
                    </button>
                </div>
                <div className="float-child" style={{width: "15%"}}>
                  <button
                  onClick={() => filterTransactions(null, downloadCsv)}
                  type="button"
                  className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Download CSV
                  </button>
                </div>
            </div>
            {/* <br></br><br></br><br></br><br></br><br></br> */}
            
            <div className="float-container">
              <div className="float-child" style={{width: "20%", zIndex: "2000"}}>
              <button
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                  type="button"
                  className="disabled:opacity-25 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Previous
                </button>
                {/* <button onClick={handlePreviousPage} disabled={page === 1}>Previous</button>                   */}
                </div>
                <div className="float-child" style={{width: "20%"}}>&nbsp;</div>
                <div className="float-child" style={{width: "20%"}}>&nbsp;</div>
                <div className="float-child" style={{width: "20%"}}>&nbsp;</div>
                <div className="float-child" style={{width: "20%"}}>
                <button
                  onClick={handleNextPage}
                  disabled={transactions.length < 1000}
                  type="button"
                  className="disabled:opacity-25 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Next
                </button>
                {/* <button className="" onClick={handleNextPage}>Next</button> */}
                </div>
            </div>
            <br></br>
            
        </div>
        <div className="bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="">
              <table className="" style={{ height: '80vh', overflowY: 'scroll', display: 'block'}}>
                <thead className="bg-gray-50 sticky top-0">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Amount
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Upi
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Trannsaction Id
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Merchant Transaction Id
                    </th>
                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Transaction Ref No
                    </th> */}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      RRN
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Merchant Code
                    </th>
                  </tr>
                </thead>
                {/* <p>No Transactions</p> */}
                <tbody className="">
                  {tlength === 0 ? <tr><td>No transactions</td></tr> : null}
                  {transactions.map((transaction) => (
                    <tr key={transaction.transaction_id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {String(transaction.created_at).slice(0, -3) +
                                " IST"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatter.format(transaction.amount)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.status}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.merchant_upi}</td>
                      <a href={"/transactiondetails?date=" + strdt + "&tid=" + transaction.transaction_id}><td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.transaction_id}</td></a>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.merchant_transaction_id}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.rrn}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.merchant_code}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </div>
    </main>
    </>
  );
}

export default Transactions;
