import { styled, ThemeProvider, useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { useState, useEffect } from 'react';
import Apiurl from "./apiurl";
import axios from 'axios';


const Chart3 = (props) => {
  const theme = useTheme();
  
  const [series, setSeries] = useState({'total-transactions': []});

  const apiu = Apiurl();

    var dateObj = new Date();

    var tyear = dateObj.getFullYear();
    var tmonth = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    var tday = dateObj.getDate().toString().padStart(2, '0');

    var dttoday = `${tyear}${tmonth}${tday}`;
    useEffect(() => {
        axios.get(apiu + `/analytics/getgraphdata?date=${dttoday}&merchant_code=${props.merchant}&merchant_upi=${props.upiid}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            let newchartdata = []
            response.data["success_transactions"].forEach(element => {
                let newdt = new Date(element["x"]);
                
                var month = newdt.toLocaleString('default', { month: 'short' });
                var day = newdt.getDate();
                var hours = newdt.getHours();
                var minutes = newdt.getMinutes();
                var seconds = newdt.getSeconds();

                day = day.toString().padStart(2, '0');
                hours = hours.toString().padStart(2, '0');
                minutes = minutes.toString().padStart(2, '0');
                seconds = seconds.toString().padStart(2, '0');

                var formattedDate = `${month} ${day} ${hours}:${minutes}:${seconds}`;
                // console.log(formattedDate);
                newchartdata.push(formattedDate);
                
            });
            // console.log(response.data["success_transactions"].reverse());
            // setChartdata4(response.data["success_transactions"].reverse());
            // setLabels4(newchartdata.reverse());
            setSeries({'total-transactions': [{'name': 'Total Transactions', 'data': response.data["total_transactions"]}]})
            // console.log(response.data["success_transactions"].reverse());
        }).catch((error) => {
            console.log(error);
        });
    }, [props.merchant, props.upiid]);

  // const series = {'last-year': [{"name": "Visitors", "data": data1}], 'this-year': [{"name": "Visitors", "data": data1}]};
  

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      width: '50%',
      height: '300 em',
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.light],
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: [theme.palette.primary.dark],
    },
    grid: {
      show: true,
      borderColor: theme.palette.divider,
      padding: {
        top: 10,
        bottom: 0,
        left: 0,
        right: 0,
      },
      position: 'back',
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: 2,
    },
    tooltip: {
      followCursor: true,
      theme: 'dark',
      x: {
        format: 'MMM dd, hh:mm:ss',
      },
      y: {
        formatter: (value) => `${value}`,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        stroke: {
          color: theme.palette.divider,
          dashArray: 0,
          width: 2,
        },
      },
      labels: {
        datetimeUTC: false,
        offsetY: 0,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      tickAmount: 20,
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
    },
    yaxis: {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      min: (min) => min - 0,
      max: (max) => max + 5,
      tickAmount: 5,
      show: true,
    },
  };

  return (
        <div className="flex flex-col flex-auto h-320">
          <ReactApexChart
            options={chartOptions}
            series={series['total-transactions']}
            type={chartOptions.chart.type}
            height={chartOptions.chart.height}
          />
        </div>
      
  );
}

export default Chart3;
