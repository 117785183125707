import React from 'react';
import Navbar from './Navbar';


function Profile() {
  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Dashboard</h1>
            </div>
          </header>
        </div>
    </div>
      {/* <Navbar />    
      <div className="container">
        <div className="row">

        </div>
      </div> */}
    </>
  );
}

export default Profile;
