import React from 'react';
import Navbar from './Navbar';
import Apiurl from './apiurl';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Datepicker from 'flowbite-datepicker/Datepicker';


function Payouts() {
  const apiu = Apiurl();

  const formatter = new Intl.NumberFormat('en-IN', {})

  const [settlements, setSettlements] = useState([]);
  const [page, setPage] = useState(1);

  const [start_date, setStartdate] = useState('');
  const [end_date, setEnddate] = useState('');
  const [start_date_filter, setStartdatefilter] = useState('');
  const [end_date_filter, setEnddatefilter] = useState('');
  
  const [merchantlist, setMerchantlist] = useState([]);
  const [merchant, setMerchant] = useState('');
  const [merchant_filter, setMerchantfilter] = useState('');
  const [statuslist, setStatuslist] = useState([{id: 1, name: 'credit'}, {id: 2, name: 'debit'}, {id: 3, name: 'in process'}, {id: 4, name: 'declined'}])
  const [status, setStatus] = useState('');
  const [status_filter, setStatusfilter] = useState('');
  const [remarkslist, setRemarkslist] = useState([{id: 1, name: 'payout attempt'}, {id: 2, name: 'payout failed'}, {id: 3, name: 'payout success'}, {id: 4, name: 'failed payout credit back'}])
  const [remarks, setRemarks] = useState('');
  const [remarks_filter, setRemarksfilter] = useState('');

  const [mridtext, setMridtext] = useState('');
  const [mrid_filter, setMridfilter] = useState('');

  const handlemridtextChange = (e) => {
    setMridtext(e.target.value);
  };


  function handleDate1(event) {
    console.log(event.target.value);
    setStartdate(event.target.value);
}

function handleDate2(event) {
    console.log(event.target.value);
    setEnddate(event.target.value);
}

const handleMerchantchange = (event) => {
  //console.log(event.target.value);
  setMerchant(event.target.value);
}

const handleStatuschange = (event) => {
  //console.log(event.target.value);
  setStatus(event.target.value);
}

const handleRemarkschange = (event) => {
    setRemarks(event.target.value);
}

function handlech() {

}

const handlePreviousPage = () => {
  setPage(prevPage => prevPage - 1);
  // setStrdt(selected);
  //console.log(`current page = ${page}`);
  //console.log(`transaction len = ${transactions.length}`);
  //console.log(`selected = ${selected}`);
};

const handleNextPage = () => {
  setPage(prevPage => prevPage + 1);
  // setStrdt(selected);
  //console.log(`current page = ${page}`);
  //console.log(`transaction len = ${transactions.length}`);
  //console.log(`selected = ${selected}`);
};

function refreshPage() {
window.location.reload();
}

const downloadCsv = async () => {
  try {
    // Make a GET request to the Flask API
    const response = await axios({
      url: apiu +
      `/payouts/downloadpayouts?start_date=${start_date}&end_date=${end_date}&type=${status}&merchant_id=${merchant}&remarks=${remarks_filter}&order_id=${mrid_filter}`, // Update this URL to match your API
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      responseType: 'blob', // Important for receiving file data
    });

    // Create a Blob from the CSV data
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');

    // Set the URL of the link element to the object URL of the Blob
    link.href = URL.createObjectURL(blob);

    // Set the download attribute of the link to the desired file name
    link.download = 'payouts.csv';

    // Simulate a click on the link to start the download
    link.click();

    // Release the object URL
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Download failed:', error);
  }
};

function filterTransactions(params, callback) {
  console.log("hello");
  if ((start_date !== '' & end_date === '') | (start_date === '' & end_date !== '')) {
    alert("please select both start date and end date");
  } else {
    let startdateformated = new Date(start_date);
    let enddateformated = new Date(end_date);
    if (!isNaN(startdateformated.getTime()) & !isNaN(enddateformated.getTime())) {
      if(startdateformated < enddateformated) {
        console.log(startdateformated);
        console.log(enddateformated);
        setStartdatefilter(start_date);
        setEnddatefilter(end_date);
      }
      if(startdateformated > enddateformated) {
        alert("end date cannot be less than start date");
      }
    }

    if(merchant !== '') {
      setMerchantfilter(merchant);
    } else {
      setMerchantfilter('');
    }
    
    if(status !== '') {
      setStatusfilter(status);
    } else {
      setStatusfilter('');
    }

    if(remarks !== '') {
        setRemarksfilter(remarks);
    } else {
        setRemarksfilter('');
    }
    
    if(mridtext !== '') {
      setMridfilter(mridtext)
    } else {
      setMridfilter('')
    }

    console.log(merchant);
    console.log(status);
    console.log(remarks);
    console.log(mridtext);
  }
  
  if (callback !== null) {
    callback();
  }

  // console.log(typeof start_date);
  // console.log(typeof end_date);
}

  useEffect(() => {
    const datepickerElstart = document.getElementById('datepickerstart');
  new Datepicker(datepickerElstart, {
      // options
      "datepicker-autohide": true,
      "datepicker-format": "dd/mm/yyyy"
  }); 

  const datepickerElend = document.getElementById('datepickerend');
  new Datepicker(datepickerElend, {
      // options
      "datepicker-autohide": true,
      "datepicker-format": "dd/mm/yyyy"
  }); 

    axios.get(apiu + "/merchants/listallmerchants", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
      let data_list = [];
      response.data.merchants_list.forEach((person) => {
        // let upi_count = Object.keys(person.upi_ids).length;
        data_list.push({id: person.merchant_id, name: person.merchant_id, mcode: person.merchant_id, appname: person.merchant_name});
      });
      setMerchantlist(data_list);
    }).catch((error) => {
      console.log(error);
    });

    axios.get(apiu + `/payouts/listallpayouts?page=${page}&start_date=${start_date_filter}&end_date=${end_date_filter}&type=${status_filter}&merchant_id=${merchant_filter}&remarks=${remarks_filter}&order_id=${mrid_filter}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
      console.log(response);
      setSettlements(response.data.settlements);
      console.log(response.data.settlements);
    }).catch((error) => {
      console.log(error);
    });

    console.log(start_date_filter);
    console.log(end_date_filter);
    console.log(merchant_filter);
    console.log(status_filter);
    console.log(remarks_filter);
    console.log(mrid_filter);

  },[page, start_date_filter, end_date_filter, merchant_filter, status_filter, remarks_filter, mrid_filter]);
  
  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Payout Transactions</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
          <br></br>
          <div className="float-container h-20">
                <div className="float-child" style={{width: "10%"}}>
                    <div className="relative max-w-sm" >
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <input value={start_date} onBlur={handleDate1} onChange={handlech} id="datepickerstart" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="start date"/>
                    </div>
                    </div>
                <div className="float-child" style={{width: "10%"}}>
                    <div className="relative max-w-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                      </div>
                      <input value={end_date} onBlur={handleDate2} onChange={handlech} id="datepickerend" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="end date"/>
                    </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={merchant} 
                    onChange={handleMerchantchange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- select merchant -- </option>
                    {merchantlist.map((mr) => (
                      // div className="user">{user}</div>
                      <option key={mr.id} value={mr.name}>
                        {mr.appname}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={status} 
                    onChange={handleStatuschange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- transaction type -- </option>
                    {statuslist.map((st) => (
                      // div className="user">{user}</div>
                      <option key={st.id} value={st.name}>
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>

                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={remarks} 
                    onChange={handleRemarkschange}
                    id="remarksselector"
                    name="remarksselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- payout status -- </option>
                    {remarkslist.map((st) => (
                      // div className="user">{user}</div>
                      <option key={st.id} value={st.name}>
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>

                <div className="float-child" style={{width: "10%"}}>
                  <button
                        onClick={() => filterTransactions(null, null)}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Apply Filter
                    </button>
                </div>
                <div className="float-child" style={{width: "10%"}}>
                    <button
                        onClick={refreshPage}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Clear Filters
                    </button>
                </div>

                
            </div>
            
            <div className="float-container h-20">
                <div className="float-child" style={{width: "60%"}}>
                  <div>&nbsp;</div>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                  <div className="relative">
                    <label
                      htmlFor="mid"
                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      OrderId
                    </label>
                    <input
                      onChange={handlemridtextChange}
                      value={mridtext}
                      type="text"
                      name="mid"
                      id="mid"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Order Id"
                    />
                  </div>
                </div>

                <div className="float-child" style={{width: "20%"}}>
                  <button
                  onClick={() => filterTransactions(null, downloadCsv)}
                  type="button"
                  className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Download CSV
                  </button>
                </div>
            </div>
            
            <div className="float-container h-10">
              <div className="float-child" style={{width: "20%", zIndex: "2000"}}>
              <button
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                  type="button"
                  className="disabled:opacity-25 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Previous
                </button>
                {/* <button onClick={handlePreviousPage} disabled={page === 1}>Previous</button>                   */}
                </div>
                <div className="float-child" style={{width: "20%"}}>&nbsp;</div>
                <div className="float-child" style={{width: "20%"}}>&nbsp;</div>
                <div className="float-child" style={{width: "20%"}}>&nbsp;</div>
                <div className="float-child" style={{width: "20%"}}>
                <button
                  onClick={handleNextPage}
                  disabled={settlements.length < 1000}
                  type="button"
                  className="disabled:opacity-25 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Next
                </button>
                {/* <button className="" onClick={handleNextPage}>Next</button> */}
                </div>
            </div>

            
            <div className="bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="">
              <table className="" style={{ height: '80vh', overflowY: 'scroll', display: 'block'}}>
                <thead className="bg-gray-50 sticky top-0">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Merchant
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Order Id
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Amount
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Cost
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Transfer Method
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Remarks
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status Code
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Details
                    </th>
                  </tr>
                </thead>
                {/* <p>No Transactions</p> */}
                <tbody className="">
                  {settlements.map((settlement) => (
                    <tr key={settlement._id.$oid}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {settlement.merchant_id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{settlement.order_id}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{settlement.datetime.$date}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatter.format(settlement.amount)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatter.format(settlement.cost)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{settlement.type}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{settlement.transfer_type}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{settlement.remarks}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{settlement.status_code}</td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{settlement.response_msg}</td> */}
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><a
                            type="button"
                            className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            href={'/payoutdetails/' + settlement._id.$oid}
                            >
                            View Details
                          </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </div>

          {/* <div className="overflow-hidden rounded-md bg-white shadow">
            <ul role="list" className="divide-y divide-gray-200">
            {settlements.map(settlement => 
            <li className="px-6 py-4" key={settlement._id.$oid}>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Merchant:&nbsp;&nbsp;<span className='font-semibold'>{settlement.merchant_id}</span></h3>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Settlement Date:&nbsp;&nbsp;<span className='font-semibold'>{settlement.created_at.$date}</span></h3>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Amount:&nbsp;&nbsp;<span>{settlement.amount}</span></h3>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Status:&nbsp;&nbsp;<span>{settlement.status}</span></h3>
              <h3 className="text-base font-semibold leading-6 text-gray-900">No of Transactions:&nbsp;&nbsp;<span>{settlement.no_of_success_txn}</span></h3>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Start DateTime:&nbsp;&nbsp;<span>{settlement.start_time.$date}</span></h3>
              <h3 className="text-base font-semibold leading-6 text-gray-900">End DateTime:&nbsp;&nbsp;<span>{settlement.end_time.$date}</span></h3>
              <a
                type="button"
                className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                href={'/editsettlement/' + settlement._id.$oid}
                >
                Edit
              </a>
            </li>
            )}
            </ul>
          </div> */}
          {/* <br></br>
          <br></br>
          <div>
          {settlements.map(settlement => 
            <div key={settlement.created_at.$date}>
              <span>{settlement.created_at.$date}</span>
              <span>{settlement.amount}</span>
              <span>{settlement.status}</span>
              <span>{settlement.no_of_success_txn}</span>
              <span>{settlement.start_time.$date}</span>
              <span>{settlement.end_time.$date}</span>
            </div>
          )}
          </div> */}
        </div>
    </main>
    </>
  );
}

export default Payouts;
