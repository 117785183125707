import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Apiurl from "./apiurl";

const Fixtransactions = () => {
  const apiu = Apiurl();
  const [date, setDate] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [rrn, setRrn] = useState("");
  const [amount, setAmount] = useState("");
  const [merchantlist, setMerchantlist] = useState([]);
  const [merchant, setMerchant] = useState('');
  const [merchant_filter, setMerchantfilter] = useState('');
  
  const handleMerchantchange = (event) => {
    setMerchant(event.target.value);
    console.log(event.target.value);
  }

  useEffect(() => {
    axios.get(apiu + "/merchants/listallmerchants", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
        let data_list = [];
        response.data.merchants_list.forEach((person) => {
            data_list.push({id: person.merchant_id, name: person.merchant_id, mcode: person.merchant_id, appname: person.merchant_name});
        });
        setMerchantlist(data_list);
    }).catch((error) => {
        console.log(error);
    });
  }, [merchant_filter]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if(merchant !== '') {
        setMerchantfilter(merchant);
    } else {
        setMerchantfilter('');
    }

    console.log(merchant);

    const formData = new FormData();
    console.log(formData);
    formData.append("merchant_id", merchant);
    formData.append("date", date);
    formData.append("trxid", transactionId);
    formData.append("amount", amount);
    formData.append("rrn", rrn);

    console.log(formData);
    try {
      axios
      .post(apiu + "/upload/fixonetransaction", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}})
      .then((response) => {
        console.log(response.data);
        window.alert(`Valid Transaction Found: ${response.data.validtransaction}\nTransaction Fixed: ${response.data.fixed}`);
      })
      .catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <>
    <div className="min-h-full">
    <div className="bg-indigo-600 pb-32">
    <Navbar />
    <header className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">Fix Transactions</h1>
        </div>
    </header>
    </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="flex flex-col items-center h-screen">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
        <select
            value={merchant} 
            onChange={handleMerchantchange}
            id="dateselector"
            name="dateselector"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
            <option selected value={''}> -- select merchant -- </option>
            {merchantlist.map((mr) => (
                // div className="user">{user}</div>
                <option key={mr.id} value={mr.name}>
                {mr.appname}
                </option>
            ))}
        </select>

          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
            Date
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            id="date"
            placeholder='YYYY-MM-DD'
            type="text"
            value={date}
            onChange={(e) => setDate(e.target.value)} />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="transactionId">
            Transaction ID
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            id="transactionId"
            type="text"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)} />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
            Amount
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            id="amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)} />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rrn">
            RRN
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            id="rrn"
            type="text"
            value={rrn}
            onChange={(e) => setRrn(e.target.value)} />
        </div>
        <div className="flex items-center justify-between">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Fix Transaction
          </button>
        </div>
      </form>
    </div>

        </div>
    </main>
    </>
  );
};

export default Fixtransactions;
