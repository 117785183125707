import React, { useState } from 'react';
import Modal from 'react-modal'; // Ensure you have a modal package installed, like react-modal

const ConversionComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [conversionRate, setConversionRate] = useState(0);
  const [INRAmount, setINRAmount] = useState(1000); // Replace this with your INR amount variable
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [apiResponse, setApiResponse] = useState(null);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setUsdtAmount(0);
    setApiResponse(null);
  };

  const handleConversion = () => {
    // Calculate the amount of USDT using the conversion rate and INR amount
    const calculatedUsdt = INRAmount / conversionRate;
    setUsdtAmount(calculatedUsdt);
  };

  const handleSendUsdt = () => {
    // Call the API to send USDT using the calculated amount
    // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
    fetch('YOUR_API_ENDPOINT', {
      method: 'POST',
      body: JSON.stringify({ usdtAmount }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApiResponse(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

return (
    <div>
      <button
        onClick={openModal}
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                
        // className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Open Modal
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="bg-white border border-gray-300 rounded-md p-6 w-96 mx-auto mt-20 opacity-100"
        overlayClassName="fixed inset-0"
      >
        <h2>Enter USDT Conversion Rate</h2>
        <input
          type="number"
          placeholder="Enter Conversion Rate"
          value={conversionRate}
          onChange={(e) => setConversionRate(parseFloat(e.target.value))}
          className="border border-gray-300 rounded-md p-2 mt-2"
        />
        <button
          onClick={handleConversion}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          //className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
        >
          Calculate USDT
        </button>
        <div>
          Calculated USDT Amount: {usdtAmount}
        </div>
        <button
          onClick={handleSendUsdt}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
        >
          Send USDT
        </button>
        {apiResponse && (
          <div className="mt-4">
            <h3>API Response:</h3>
            <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
          </div>
        )}
        <button onClick={closeModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">
          Close Modal
        </button>
      </Modal>
    </div>
  );
};

export default ConversionComponent;
