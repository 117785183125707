import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react'
import Apiurl from './apiurl';
import axios from 'axios';

function Merchant() {
  const apiu = Apiurl();
  const [people, setPeople] = useState([]);
  const [statuses, setStatuses] = useState({});

  
  useEffect(() => {
    axios.get(apiu + "/merchants/listallmerchants", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
      let initialStatuses = {};
      let data_list = [];
      response.data.merchants_list.forEach((person) => {
        let upi_count = Object.keys(person.upi_ids).length;
        data_list.push({name: person.merchant_name, mcode: person.merchant_id, upiidnum: upi_count, status: person.merchant_status});
      });
      setPeople(data_list);
      data_list.forEach((person) => {
        initialStatuses[person.mcode] = person.status;
        // console.log(person.status);
      });
      setStatuses(initialStatuses);
      
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  // const [people, setPeople] = useState([
  //   { name: 'Merchant 1', mcode: 'M0001', upiidnum: 5, status: false },
  //   { name: 'Merchant 2', mcode: 'M0002', upiidnum: 4, status: true },
  //   { name: 'Merchant 3', mcode: 'M0003', upiidnum: 2, status: true },
  //   { name: 'Merchant 4', mcode: 'M0004', upiidnum: 0, status: false },
  // ]);

  // const [statuses, setStatuses] = useState(() => {
  //   const initialStatuses = {};
  //   people.forEach((person) => {
  //     initialStatuses[person.mcode] = person.status;
  //   });
  //   return initialStatuses;
  // });

  const handleStatusChange = (mcode) => {
    const newStatuses = { ...statuses };
    newStatuses[mcode] = !newStatuses[mcode];

    const formData = new FormData();
    formData.append("merchant_id", mcode);

      axios.post(apiu + "/merchants/changemerchantstatus", formData, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
        console.log(response)
        setStatuses(newStatuses);
      }).catch((error) => {
        console.log(error.response.data.error);
        const error_msg = error.response.data.error;
        const error_code = error.response.status;
        if (error_code == 999) {
          if (error_msg == "cannot activate merchant without upi"){
           alert("cannot activate merchant without any upi id")
          }
        }
      });
  };

  const tabs = [
    { name: 'List', href: '/merchants', current: true },
    { name: 'Add New', href: '/addmerchant', current: false },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  if(performance.navigation.type == 2){
    window.location.reload();
  }

  return (
    <>
    <script type="text/javascript">  
      
    </script>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Merchants</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
          <br></br>
            <div className="border-b border-gray-200">
            <div className="sm:flex sm:items-baseline">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Merchants</h3>
                <div className="mt-4 sm:ml-10 sm:mt-0">
                <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                    <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                        tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                    >
                        {tab.name}
                    </a>
                    ))}
                </nav>
                </div>
            </div>
            </div>

          <br></br>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Merchant Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Active Upi Ids
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Edit Merchant
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {people.map((person) => (
                          <tr key={person.mcode}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {person.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.upiidnum}</td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-0">
                              <a href={'/editmerchant/' + person.mcode} className="text-indigo-600 hover:text-indigo-900">
                                Edit<span className="sr-only">, {person.name}</span>
                              </a>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                              <label className="relative inline-flex items-center cursor-pointer">
                                  <input type="checkbox" value="" className="sr-only peer"
                                  checked={statuses[person.mcode] || false}
                                  onChange={() => handleStatusChange(person.mcode)}
                                  />
                                  {statuses[person.mcode] ? true : false}
                                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                              </label>
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
    </main>
    </>
  );
}

export default Merchant;
