import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react'
import axios from 'axios';
import Apiurl from './apiurl';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'


function Addupi() {
    const apiu = Apiurl();

    const tabs = [
        { name: 'List', href: '/upi', current: false },
        { name: 'Add New', href: '/addupi', current: true },
      ]
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
      let [upi_name, setUpiname] = useState("");
      let [vpa, setVpa] = useState("");
      let [dailylimit, setDailylimit] = useState("");
      let [upi_status, setUpistatus] = useState("");

      const handleupinamechange = (e) => {
        setUpiname(e.target.value);
      };

      const handlevpachange = (e) => {
        setVpa(e.target.value);
      };

      const handledailylimitchange = (e) => {
        setDailylimit(e.target.value);
      };

      const handleupistatuschange = (e) => {
        setUpistatus(e.target.checked);
      };


      const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("upi_name", upi_name);
        formData.append("vpa", vpa);
        if (dailylimit == "") {
            dailylimit = 0;
        }
        if (upi_status == ""){
            upi_status = false;
        } else {
            upi_status = true;    
        }

        formData.append("daily_limit", dailylimit);
        if (upi_status === true) {
            formData.append("upi_status", upi_status);
        } 
        
       axios
      .post(apiu + "/upi/createupi", formData, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
      })
      .then((response) => {
        console.log(response)
        alert("upi id added succesfully");
        window.location = '/upi';
      })
      .catch((error) => {
        console.log(error);
      });

      };

  return (
    <>
    <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
        <Navbar />
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Upi</h1>
            </div>
          </header>
        </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="border-b border-gray-200">
            <div className="sm:flex sm:items-baseline">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Upi Ids</h3>
                <div className="mt-4 sm:ml-10 sm:mt-0">
                <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                    <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                        tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                    >
                        {tab.name}
                    </a>
                    ))}
                </nav>
                </div>
            </div>
            </div>

            <br></br>

            <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Add new upi id</h2>
                    
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="upiname" className="block text-sm font-medium leading-6 text-gray-900">
                            Upi Identifier
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="upiname"
                                id="upiname"
                                value={upi_name}
                                onChange={handleupinamechange}
                                autoComplete="upiname"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="upi identifier"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="vpa" className="block text-sm font-medium leading-6 text-gray-900">
                            Upi Vpa
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="vpa"
                                id="vpa"
                                onChange={handlevpachange}
                                value={vpa}
                                autoComplete="vpa"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="vpa@bank"
                                required
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        <label htmlFor="limit" className="block text-sm font-medium leading-6 text-gray-900">
                            Upi Limit
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                min="0"
                                type="number"
                                name="limit"
                                id="limit"
                                onChange={handledailylimitchange}
                                value={dailylimit}
                                autoComplete="limit"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="0"
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                        
                        <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                            Upi Status
                        </label>

                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" name="status"
                                onChange={handleupistatuschange}
                                id="status" value={upi_status} className="sr-only peer"
                            />
                            
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>

                        
                        </div>
                    </div>

                    </div>
                </div>

                <div className="mt-6 flex items-center gap-x-6">
                    
                    <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Save
                    </button>
                </div>
            </form>

        </div>
    </main>
    </>
  );
}

export default Addupi;
