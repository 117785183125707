import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import Apiurl from "./apiurl";

const Transactiondetails = () => {
  const apiu = Apiurl();
  const [params] = useSearchParams();
  
  const date = params.get('date');
  const tid = params.get('tid');
  
  const [transaction_state, setTransactionstate] = useState("");
  const [provider_payload, setPpayload] = useState("");
  const [provider_response, setPresponse] = useState("");
  const [provider_code, setPcode] = useState("");
  const [merchant_payload, setMpayload] = useState("");
  const [merchant_response, setMresponse] = useState("");
  const [merchant_code, setMcode] = useState("");

  useEffect(() => {
    axios.get(apiu + "/transactions/gettransactiondetails?date=" + date +  "&transaction_id=" + tid, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
        console.log(response.data.transaction_data);
        setTransactionstate(response.data.transaction_data.transaction_state);
        
        setMpayload(response.data.transaction_data.merchant_cb_payload);
        setMresponse(response.data.transaction_data.merchant_cb_response);
        setMcode(response.data.transaction_data.merchant_cb_statuscode);
        
        setPpayload(response.data.transaction_data.provider_payload);
        setPresponse(response.data.transaction_data.provider_response);
        setPcode(response.data.transaction_data.provider_status_code);

    }).catch((error) => {
        console.log(error);
    });
  },);

  return (
    <>
    <div className="min-h-full">
    <div className="bg-indigo-600 pb-32">
    <Navbar />
    <header className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">Transaction Details</h1>
        </div>
    </header>
    </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
                <div className="flex flex-col">
                    <b>Transaction State</b>
                    <div>{transaction_state}</div>
                </div>
            </div>
            <br></br>
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <b>Provider Request Payload</b>
                <div>{provider_payload}</div>
            </div>
        </div>
        <br></br>
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <b>Provider Response</b>
                <div>{provider_response}</div>
            </div>
        </div>
        <br></br>
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <b>Provider Status Code</b>
                <div>{provider_code}</div>
            </div>
        </div>
        <br></br>
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <b>Merchant Callback Payload</b>
                <div>{merchant_payload}</div>
            </div>
        </div>
        <br></br>
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <b>Merchant Callback Response</b>
                <div>{merchant_response}</div>
            </div>
        </div>
        <br></br>
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl pt-4 px-4 pb-4 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <b>Merchant Callback Status Code</b>
                <div>{merchant_code}</div>
            </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        </div>
    </main>
    </>
  );
};

export default Transactiondetails;
