import { useEffect , useState} from "react";
import React from 'react';
import Navbar from './Navbar';
import 'flowbite/dist/flowbite.min.css';
import Datepicker from 'flowbite-datepicker/Datepicker';
import Apiurl from "./apiurl";
import axios from "axios";


function ManagePayouts() {
    const apiu = Apiurl();
    
    const formatter = new Intl.NumberFormat('en-IN', {})

    
    const [merchantlist, setMerchantlist] = useState([]);
    const [merchant, setMerchant] = useState('');
    const [merchant_filter, setMerchantfilter] = useState('');
    
    const [successamt, setSuccessamt] = useState(0);
    const [successnum, setSuccessnum] = useState(0);
    const [wbalance, setWbalance] = useState(0);

    const handleMerchantchange = (event) => {
        setMerchant(event.target.value);
    }
    
    const[stats, setStats] = useState([]);

    
    function handlech() {

    }

    function refreshPage() {
    window.location.reload();
    }


    useEffect(() => {

        axios.get(apiu + "/merchants/listallmerchants", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            let data_list = [];
            response.data.merchants_list.forEach((person) => {
                data_list.push({id: person.merchant_id, name: person.merchant_id, mcode: person.merchant_id, appname: person.merchant_name});
            });
            setMerchantlist(data_list);
        }).catch((error) => {
            console.log(error);
        });
    
        let success_amount = 0;
        let success_transactions = 0;
        let wallet_balance = 0;
        let initialStats = [];

        axios.get(apiu + "/payouts/getplatformbalance", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            
            let rsp = response.data;
            let p1 = rsp.search("balance': '");
            let p2 = rsp.indexOf("'", p1+11);

            success_amount = formatter.format(parseFloat(rsp.substring(p1+11, p2)));
            initialStats.push({ name: 'Payouts Platform Balance', stat: success_amount });

            axios.get(apiu + "/payouts/getwalletsbalance", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
                success_transactions = response2.data.balance;
                wallet_balance = response2.data.wbalance;
                initialStats.push({name: 'Payouts Wallet Balance', stat: formatter.format(success_transactions)});
                initialStats.push({name: 'Wallet Balance', stat: formatter.format(wallet_balance)});
                setStats(initialStats);
            }).catch((error) => {
                console.log(error);
            });
            
          }).catch((error) => {
            console.log(error);
          });
        
    }, []);

    useEffect(() => {
        console.log("filter worked");
        let success_amount = 0;
        let success_transactions = 0;
        let wallet_balance = 0;
        axios.get(apiu + "/payouts/getplatformbalance", {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response) => {
            
            let rsp = response.data;
            let p1 = rsp.search("balance': '");
            let p2 = rsp.indexOf("'", p1+11);

            success_amount = parseFloat(rsp.substring(p1+11, p2));
            setSuccessamt(success_amount);

            axios.get(apiu + `/payouts/getwalletsbalance?merchant_id=${merchant_filter}`, {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}).then((response2) => {
                success_transactions = response2.data.balance;
                wallet_balance = response2.data.wbalance;
                setSuccessnum(success_transactions);
                setWbalance(wallet_balance);
            }).catch((error) => {
                console.log(error);
            });
            
          }).catch((error) => {
            console.log(error);
          });
    }, [merchant_filter]);

    useEffect(() => {

        let initialStats = [
            { name: 'Payouts Platform Balance', stat: formatter.format(successamt) },
            { name: 'Payouts Wallet Balance', stat: formatter.format(successnum) },
            { name: 'Wallet balance', stat: formatter.format(wbalance) }
        ];

        setStats(initialStats);
    }, [successamt, successnum, wbalance]);

    function applyFilter() {
        setSuccessamt(0);
        setSuccessnum(0);
        setWbalance(0);

        if(merchant !== '') {
            setMerchantfilter(merchant);
        } else {
            setMerchantfilter('');
        }

    }

  return (
    <> 
    <div className="min-h-full">
    <div className="bg-indigo-600 pb-32">
    <Navbar />
    <header className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">Manage Payouts</h1>
        </div>
    </header>
    </div>
    </div>
    <main className="-mt-32">
        <div className="overflow-hidden rounded-lg bg-white shadow mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <br></br>
            <div className="float-container h-20">
                
                <div className="float-child" style={{width: "20%"}}>
                  {/* selector */}
                <div>
                  {/* <label htmlFor="dateselector" className="block text-sm font-medium leading-6 text-gray-900">
                    Date
                  </label> */}
                  <select
                    value={merchant} 
                    onChange={handleMerchantchange}
                    id="dateselector"
                    name="dateselector"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    
                  >
                    <option selected value={''}> -- select merchant -- </option>
                    {merchantlist.map((mr) => (
                      // div className="user">{user}</div>
                      <option key={mr.id} value={mr.name}>
                        {mr.appname}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                
                <div className="float-child" style={{width: "20%"}}>
                    <button
                        onClick={applyFilter}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Apply Filters
                    </button>
                </div>
                <div className="float-child" style={{width: "20%"}}>
                    <button
                        onClick={refreshPage}
                        type="button"
                        className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Clear Filters
                    </button>
                </div>
            </div>
            
            <br></br>
            <div>
                {/* <br></br><br></br><br></br> */}
                <h3 className="text-base font-semibold leading-6 text-gray-900">Stats</h3>
                <dl className="mt-5 grid grid-cols-3 gap-5 sm:grid-cols-3">
                    {stats.map((item) => (
                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                    </div>
                    ))}
                </dl>
            </div>
            <br></br>
        </div>
    </main>
    
    </>
    );
}

export default ManagePayouts;
